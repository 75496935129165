import { Fragment, useEffect, useState } from "react"
import { Button } from "@fastdo-react"
import { ArticleCard, CircularProgressPage, Spacer } from "@fastdo-react"
import { Link } from "react-router-dom"
import { Articles_Get_GetResponse } from "common/api/exportedModels"
import agent from "common/api/agent"

export default function ArticleList() {
  const pageSize = 10
  const [page, setPage] = useState(1)
  const [response, setResponse] = useState<Articles_Get_GetResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState(false)

  useEffect(() => {
    const get = async () => {
      page === 1 ? setLoading(true) : setLoadingNext(true)
      try {
        var queryString = new URLSearchParams({ pageNumber: page.toString(), pageSize: pageSize.toString() })
        var response = await agent.Articles.get(queryString)
        page === 1
          ? setResponse(response)
          : setResponse((prev) => {
              if (!prev || !prev.items) return prev
              return {
                ...prev,
                items: [...prev.items, ...(response.items ?? [])],
              }
            })
      } catch {}
      page === 1 ? setLoading(false) : setLoadingNext(false)
    }
    get()
  }, [page])

  if (loading) return <CircularProgressPage />

  return (
    <>
      {response?.items?.map((item, index) => (
        <Fragment key={index}>
          <ArticleCard
            LinkComponent={Link}
            link={"/articles/" + item.nameUrl}
            name={item.name!}
            imageUrl={process.env.REACT_APP_API_URL + "/MediaImages/GetSingle/" + item.imageName}
          />
          <Spacer size={20} />
        </Fragment>
      ))}
      <Spacer />

      {response?.items && response.items.length !== response.totalCount && (
        <div style={{ textAlign: "center" }}>
          <Button label="Načíst další" onClick={() => setPage((_) => _ + 1)} loading={loadingNext} />
        </div>
      )}
    </>
  )
}
