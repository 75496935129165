import { Fragment, useEffect, useState } from "react"
import moment from "moment"
import agent from "../../../common/api/agent"
import { toast } from "react-toastify"
import { ActionRowCard, Button, ButtonWithAsk, CircularProgressPage, FileUploadCard, Spacer } from "@fastdo-react"
import { Link, useNavigate } from "react-router-dom"
import { AiFillDelete, AiFillEdit, AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { Articles_Get_GetResponse } from "common/api/exportedModels"

export default function AdminArticleList() {
  const pageSize = 10
  const [page, setPage] = useState(1)
  const [response, setResponse] = useState<Articles_Get_GetResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState(false)
  const [loadingDeleteId, setLoadingDeleteId] = useState<string | undefined>(undefined)
  const [loadingUpId, setLoadingUpId] = useState<string | undefined>(undefined)
  const [loadingDownId, setLoadingDownId] = useState<string | undefined>(undefined)
  const [loadingGetCsv, setLoadingGetCsv] = useState(false)

  const get = async () => {
    page === 1 ? setLoading(true) : setLoadingNext(true)
    try {
      var queryString = new URLSearchParams({ pageNumber: page.toString(), pageSize: pageSize.toString() })
      var response = await agent.Articles.get(queryString)
      page === 1
        ? setResponse(response)
        : setResponse((prev) => {
            if (!prev || !prev.items) return prev
            return {
              ...prev,
              items: [...prev.items, ...(response.items ?? [])],
            }
          })
    } catch {}
    page === 1 ? setLoading(false) : setLoadingNext(false)
  }

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const setPageRerender = async (pageNumber: number) => {
    if (pageNumber === page) await get()
    else setPage(pageNumber)
  }

  const del = async (id: string) => {
    setLoadingDeleteId(id)
    try {
      await agent.Articles.delete(id)
      setResponse((prev) => {
        if (!prev || !prev.items) return prev
        return {
          ...prev,
          items: prev.items.filter((item) => item.id !== id),
          totalCount: prev.totalCount! - 1,
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
    setLoadingDeleteId(undefined)
  }

  const delAll = async () => {
    try {
      await agent.Articles.deleteAll()
      setResponse((prev) => {
        if (!prev || !prev.items) return prev
        return {
          ...prev,
          items: [],
          totalCount: 0,
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
  }

  const up = async (id: string) => {
    setLoadingUpId(id)
    try {
      await agent.Articles.moveUp(id)
      setPageRerender(1)
      toast.success("Úspěšně posunuto nahoru")
    } catch {}
    setLoadingUpId(undefined)
  }

  const down = async (id: string) => {
    setLoadingDownId(id)
    try {
      await agent.Articles.moveDown(id)
      setPageRerender(1)
      toast.success("Úspěšně posunuto dolu")
    } catch {}
    setLoadingDownId(undefined)
  }

  const uploadCsv = async (file: File) => {
    await agent.Articles.uploadCsv(file)
    toast.success("Úspěšně nahráno")
    setPageRerender(1)
  }

  const getCsv = async () => {
    setLoadingGetCsv(true)
    try {
      await agent.Articles.getCsv()
    } catch {}
    setLoadingGetCsv(false)
  }

  const navigate = useNavigate()

  return (
    <>
      <FileUploadCard
        fileSelectorLabel="Nahrajte .csv soubor s články"
        acceptedFileTypes={[".csv"]}
        onUpload={uploadCsv}
      />
      <Spacer />

      <Button label="Stáhnout csv" onClick={getCsv} loading={loadingGetCsv} />
      <Spacer />

      <Link to="/admin/articles/create" className="fastdo-button fastdo-button-default">
        Vytvořit článek
      </Link>
      <Spacer />

      <ButtonWithAsk
        actionWithAskProps={{
          title: "Odstranit vše",
          onAccepted: delAll,
        }}
        buttonProps={{
          label: "Odstranit vše",
          danger: true,
        }}
      />
      <Spacer />

      {loading ? (
        <CircularProgressPage />
      ) : (
        <>
          {response?.items?.map((item) => (
            <Fragment key={item.nameUrl}>
              <ActionRowCard
                key={item.nameUrl}
                id={item.nameUrl!}
                label={item.name! + ", " + moment(item.lastUpdated).format("DD.MM.YYYY HH:mm:ss")}
                actionItems={[
                  {
                    icon: AiFillEdit,
                    onClick: () => navigate(`/admin/articles/edit/${item.nameUrl}`),
                  },
                  {
                    icon: AiFillDelete,
                    onClick: () => del(item.id!),
                    loading: loadingDeleteId === item.id,
                  },
                  {
                    icon: AiOutlineArrowUp,
                    onClick: () => up(item.id!),
                    loading: loadingUpId === item.id,
                  },
                  {
                    icon: AiOutlineArrowDown,
                    onClick: () => down(item.id!),
                    loading: loadingDownId === item.id,
                  },
                ]}
              />
              <Spacer />
            </Fragment>
          ))}

          {response?.items && response.items.length !== response.totalCount && (
            <div style={{ textAlign: "center" }}>
              <Button label="Načíst další" onClick={() => setPage((_) => _ + 1)} loading={loadingNext} />
            </div>
          )}
        </>
      )}
    </>
  )
}
