import styles from "./AnalysisPage.module.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import MutualFundsDetailComparison from "../../../components/general/MutualFundsDetailComparison"
import InvestmentModelationCard from "../../../components/investmentModelation/InvestmentModelationCard"
import RecommendationTextField from "components/mutalFunds/RecommendationTextField"
import { HomeLayout, PrimaryCircularProgress, Spacer, getUrlGetParam } from "@fastdo-react"
import Head from "react-helmet"
import FunnelChart from "components/mutalFunds/plots/FunnelChart"
import {
  AnalysisMutualFunds_GetComparisonData_GetComparisonDataRequest,
  MutualFunds_GetDetail_GetDetailResponse,
} from "common/api/exportedModels"
import agent from "common/api/agent"

export default function AnalysisPage() {
  const isina = getUrlGetParam("isina") ?? ""
  const isinb = getUrlGetParam("isinb") ?? ""

  const [loading, setLoading] = useState(true)
  const [mappedData, setMappedData] = useState<any[]>([])
  const [mutualFundDetailA, setMutualFundDetailA] = useState<MutualFunds_GetDetail_GetDetailResponse>()
  const [mutualFundDetailB, setMutualFundDetailB] = useState<MutualFunds_GetDetail_GetDetailResponse>()

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    const get = async () => {
      setLoading(true)

      var request: AnalysisMutualFunds_GetComparisonData_GetComparisonDataRequest = {
        isina: isina,
        isinb: isinb,
      }

      try {
        var response = await agent.AnalysisMutualFunds.getComparisonData(request)

        setMutualFundDetailA(response.mutualFundDetailA)
        setMutualFundDetailB(response.mutualFundDetailB)

        var newMappedData = []
        for (var i = 0; i < response!.analysisDataA!.maximums!.length!; i++) {
          newMappedData.push({
            name: (1 + i / 12).toFixed(2),
            maxA: [response.analysisDataA?.maximums![i], response.analysisDataA?.minimums![i]],
            maxB: [response.analysisDataB?.maximums![i], response.analysisDataB?.minimums![i]],
            medA: [response.analysisDataA?.medians![i], response.analysisDataA?.medians![i]],
            medB: [response.analysisDataB?.medians![i], response.analysisDataB?.medians![i]],
            minA: [response.analysisDataA?.minimums![i], response.analysisDataA?.minimums![i]],
            minB: [response.analysisDataB?.minimums![i], response.analysisDataB?.minimums![i]],
          })
        }
        setMappedData(newMappedData)

        setLoading(false)
      } catch {}
    }
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isina, isinb])

  const navigate = useNavigate()

  return (
    <HomeLayout
      headProps={{
        HeadHelement: Head,
        title: "Srovnávač - Srovnej investice",
        link: "https://srovnejinvestice.cz/analysis",
        imageCard: "https://srovnejinvestice.cz/assets/logos/logo-text.png",
      }}
      title="Srovnávač"
    >
      <div className={styles.textFieldSelectionDiv}>
        <div className={styles.textFieldSelectionLeftDiv}>
          <RecommendationTextField
            label="Zvolte fond A"
            onRecommendationClick={(value: string) =>
              navigate(`/analysis/?isina=${value}&isinb=${mutualFundDetailB?.isin}`)
            }
          />
          <Spacer size={20} />
          <div className={styles.textFieldSelectionLabelDiv}>
            {loading ? (
              <PrimaryCircularProgress size={40} />
            ) : (
              <h3 style={{ color: "#e76f51" }}>{mutualFundDetailA?.name}</h3>
            )}
          </div>
        </div>

        <div className={styles.textFieldSelectionRightDiv}>
          <RecommendationTextField
            label="Zvolte fond B"
            onRecommendationClick={(value: string) =>
              navigate(`/analysis/?isina=${mutualFundDetailA?.isin}&isinb=${value}`)
            }
          />
          <Spacer size={20} />

          <div className={styles.textFieldSelectionLabelDiv}>
            {loading ? (
              <PrimaryCircularProgress size={40} />
            ) : (
              <h3 style={{ color: "#264653" }}>{mutualFundDetailB?.name}</h3>
            )}
          </div>
        </div>
      </div>
      <Spacer size={20} />

      {loading ? (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryCircularProgress />
        </div>
      ) : (
        <>
          <InvestmentModelationCard mutualFundDetailA={mutualFundDetailA!} mutualFundDetailB={mutualFundDetailB!} />
          <Spacer size={40} />

          <FunnelChart data={mappedData!} />
          <Spacer size={40} />

          <MutualFundsDetailComparison mutualFundA={mutualFundDetailA!} mutualFundB={mutualFundDetailB!} />
        </>
      )}
    </HomeLayout>
  )
}
