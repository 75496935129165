import { useState } from "react"
import InProgressModal from "./InProgressModal"
import { Button } from "@fastdo-react"

export interface Props {
  label?: string
}

export default function ButtonWithInProgressModal({ label = "Chci investovat" }: Props) {
  const [show, setShow] = useState(false)

  return (
    <>
      {show && <InProgressModal onClose={() => setShow(false)} />}
      <Button label={label} onClick={() => setShow(true)} />
    </>
  )
}
