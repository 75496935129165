import agent from "common/api/agent"
import { ScatterHighlights_Get_GetResponse, ScatterHighlights_Get_GetItemResponse } from "common/api/exportedModels"
import { Fragment, useEffect, useState } from "react"
import { CircularProgressPage, ActionRowCard, Spacer } from "@fastdo-react"
import { AiFillDelete, AiFillEdit } from "react-icons/ai"
import { toast } from "react-toastify"
import ScatterHighlightModal from "../mutalFunds/ScatterHighlightModal"

export default function Table() {
  const [response, setResponse] = useState<ScatterHighlights_Get_GetResponse | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [editItem, setEditItem] = useState<ScatterHighlights_Get_GetItemResponse | undefined>(undefined)
  const [loadingDeleteId, setLoadingDeleteId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      var response = await agent.ScatterHighlights.get()
      setResponse(response)
      setLoading(false)
    }
    get()
  }, [])

  const deleteItem = async (id: string | undefined) => {
    if (!id) return
    setLoadingDeleteId(id)
    try {
      await agent.ScatterHighlights.delete(id)
      setResponse((prev) => {
        if (!prev || !prev.items) return prev
        return {
          ...prev,
          items: prev.items.filter((item) => item.id !== id),
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
    setLoadingDeleteId(undefined)
  }

  const handleEdit = (performed: boolean, item: ScatterHighlights_Get_GetItemResponse | undefined) => {
    if (performed && item)
      setResponse((prev) => {
        if (!prev || !prev.items) return prev
        return {
          ...prev,
          items: prev.items.map((i) => (i.id === item.id ? item : i)),
        }
      })
    setEditItem(undefined)
  }

  if (loading) return <CircularProgressPage />

  return (
    <div>
      {editItem && (
        <ScatterHighlightModal editItem={editItem} onClose={(performed, item) => handleEdit(performed, item)} />
      )}

      {response?.items?.map((item, index) => (
        <Fragment key={index}>
          <ActionRowCard
            id={index}
            labelComponent={
              <div>
                <p>
                  <strong>{item.name}</strong>
                </p>
                <p>{item.isin}</p>
              </div>
            }
            actionItems={[
              {
                icon: AiFillEdit,
                onClick: () => setEditItem(item),
              },
              {
                icon: AiFillDelete,
                onClick: () => deleteItem(item.id),
                loading: loadingDeleteId === item.id,
              },
            ]}
            actionComponent={
              <div style={{ width: "30px", height: "30px", borderRadius: "20rem", backgroundColor: item.color }}></div>
            }
          />
          <Spacer />
        </Fragment>
      ))}
    </div>
  )
}
