import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import agent from "../api/agent"
import { AppData_Get_GetResponse } from "../api/exportedModels"

export interface InitialState {
  loading: boolean
  response: AppData_Get_GetResponse | undefined
}

const initialState: InitialState = {
  loading: true,
  response: undefined,
}

export const appDataSlice = createSlice({
  name: "appData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAppData.fulfilled, (state, action) => {
      state.response = action.payload
      state.loading = false
    })
    builder.addCase(getAppData.rejected, (state) => {
      state.loading = false
    })
  },
})

export default appDataSlice.reducer

export const getAppData = createAsyncThunk("appData/getAppData", async () => {
  const response = await agent.AppData.get()
  return response

  // return Promise.reject()
})
