import { useState } from "react"
import ReactQuill from "react-quill"
import { Button, ImageSelector, Quill, Spacer, TextArea, TextField } from "@fastdo-react"
import katex from "katex"

export interface Props {
  article: Article
  onChange: (article: Article) => void
  imageNames: string[]
  onSubmit: () => void
  loadingSubmit: boolean
  buttonLabel: string
}

export interface Article {
  name: string
  imageName: string
  description: string
  content: string
}

export default function ActionArticleCard({
  article,
  onChange,
  imageNames,
  onSubmit,
  loadingSubmit,
  buttonLabel,
}: Props) {
  const [showImageSelector, setShowImageSelector] = useState(false)

  return (
    <>
      <TextField label="Název" value={article.name} onChange={(value) => onChange({ ...article, name: value })} />
      <Spacer />

      <TextArea
        label="Popis"
        value={article.description!}
        onChange={(value) => onChange({ ...article, description: value })}
      />
      <Spacer />

      {showImageSelector && (
        <ImageSelector
          imageNames={imageNames}
          getImageUrl={process.env.REACT_APP_API_URL + "/MediaImages/GetSingle"}
          show
          onSelect={(value) => {
            onChange({ ...article, imageName: value })
            setShowImageSelector(false)
          }}
          onClose={() => setShowImageSelector(false)}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <Button label="Zvolte obrázek" onClick={() => setShowImageSelector(true)} />

        {article.imageName && (
          <>
            <Spacer />
            <img
              src={process.env.REACT_APP_API_URL + "/MediaImages/GetSingle/" + article.imageName}
              alt="img"
              loading="eager"
              style={{ width: "90%", border: "1px solid var(--fastdo-dark-color)" }}
            />
          </>
        )}
      </div>
      <Spacer />

      <div style={{ overflow: "hidden" }}>
        <Quill
          ReactQuill={ReactQuill}
          katex={katex}
          content={article.content!}
          onContentChange={(value: string) => onChange({ ...article, content: value })}
          imageNames={imageNames}
          getImageUrl={process.env.REACT_APP_API_URL + "/MediaImages/GetSingle"}
          placeholder="Napište obsah."
        />
      </div>
      <Spacer />

      <div style={{ textAlign: "center" }}>
        <Button label={buttonLabel} onClick={onSubmit} loading={loadingSubmit} />
      </div>
    </>
  )
}
