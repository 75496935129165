import MutualFundsWithUnuploadedAnalysis from "components/pages/analysis/MutualFundsWithUnuploadedAnalysis"
import agent from "../../../common/api/agent"
import { HomeLayout, MultipleUploadCard } from "@fastdo-react"

export default function AnalysisPage() {
  return (
    <HomeLayout title="Analýza">
      <MultipleUploadCard
        fileSelectorLabel="Nahrajte csv soubory s analýzou"
        acceptedFileTypes={[".csv"]}
        onUpload={(file) => agent.AnalysisMutualFunds.uploadCsv(file!)}
      />
      <MutualFundsWithUnuploadedAnalysis />
    </HomeLayout>
  )
}
