import MemberCard from "./MemberCard"
import { Spacer } from "@fastdo-react"

export default function Members() {
  return (
    <>
      <MemberCard imgSrc="/assets/images/about/stefan-novota.webp" name="RNDr. Štefan Novota">
        <p>
          Vystudoval jsem jadernou fyziku na MFF UK v Praze a absolvoval jsem postgraduální studium na Vysoké škole
          ekonomické v Praze. Na začátku 90. let 20. století jsem pracoval jako finanční analytik v investiční
          společnosti, kterou jsem spoluzakládal. Od poloviny 90. let až do roku 2012 jsem podnikal. Ve firmě, která
          vyvíjela a vyráběla produkty pro armádu, bezpečnostní složky a volný čas, jsem měl na starosti ekonomiku,
          finance a obchod.
        </p>
        <p>
          Od roku 2012 pracuji jako ekonomický a finanční poradce. Specializuji se především na problematiku
          dlouhodobého spoření a investování. Vypracoval jsem unikátní metodu, která definuje kvalitu podílových fondů,
          umožňuje porovnávat podílové fondy mezi sebou a vybírat k investování takové, které mají při nízkém riziku
          ztráty vysoký výnos. Na téma spoření, investování a důchodové reformy publikuji odborné články.
        </p>
      </MemberCard>
      <Spacer size={20} />
      <MemberCard picureRight imgSrc="/assets/images/about/petr-novota.webp" name="Bc. Petr Novota">
        <p>
          Po dokončení německého gymnázia v Liberci jsem se šest let věnoval nezávislému finančnímu poradenství. Z
          tohoto období pramení můj zájem o oblast dlouhodobých investic do podílových a ETF fondů. V té době jsem
          nabízel lidem podílové fondy doporučované poradenskou společností, ve které jsem pracoval. Věřil jsem
          informacím, které jsem dostával na investičních školeních.
        </p>
        <p>
          Bohužel, výkonnost doporučovaných fondů se ukázala být s odstupem času špatná, a proto jsem se intenzivně
          začal zajímat o to, jak mezi asi patnácti sty fondy, které jsou u nás nabízeny, vybrat ty nejlepší. Do
          stejného oboru jsem přivedl svého otce, který se mu věnuje úspěšně dodnes a který je tvůrcem metody, která
          umožňuje vybírat ke spoření nejkvalitnější podílové fondy.
        </p>
        <p>
          Dokončil jsem vysokoškolské studium elektrotechniky a informačních technologií na ETH v Zürichu a kybernetiky
          a robotiky na ČVUT v Praze. V průběhu studií jsem našel svoji další vášeň, programování, kterému se dnes
          věnuji profesně.
        </p>
        <p>
          To mně umožnilo vytvořit program, který stahuje všechna dostupná data o všech podílových fondech nabízených v
          ČR.
        </p>
        <p>
          Data jsou zpracována naší unikátní metodou porovnávání kvality podílových fondů a výsledky jsou vám k
          dispozici na našem webu.
        </p>
      </MemberCard>
    </>
  )
}
