import { HomeLayout } from "@fastdo-react"
import MediaImages from "components/pages/media/MediaImages"

export default function MediaPage() {
  return (
    <HomeLayout title="Média">
      <MediaImages />
    </HomeLayout>
  )
}
