import { useState, useEffect } from "react"
import {
  Articles_Create_CreateRequest,
  MediaImages_GetNames_GetNamesResponse,
} from "../../../common/api/exportedModels"
import { CircularProgressPage } from "@fastdo-react"
import agent from "common/api/agent"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ActionArticleCard, { Article } from "./ActionArticleCard"

export default function CreateArticleCard() {
  const [imageNamesResponse, setImageNamesResponse] = useState<MediaImages_GetNames_GetNamesResponse | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState(false)
  const [request, setRequest] = useState<Articles_Create_CreateRequest>({
    name: "",
    imageName: "",
    description: "",
    content: "",
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      try {
        var imageNamesResponse = await agent.MediaImages.getNames()
        imageNamesResponse.items?.sort()
        setImageNamesResponse(imageNamesResponse)
      } catch {}
      setLoading(false)
    }
    get()
  }, [])

  const navigate = useNavigate()

  const submit = async () => {
    setLoadingSubmit(true)
    try {
      await agent.Articles.create(request)
      toast.success("Úspěšně vytvořeno")
      navigate("/admin/articles")
    } catch {}
    setLoadingSubmit(false)
  }

  if (loading) return <CircularProgressPage />

  return (
    <>
      <ActionArticleCard
        article={{
          name: request.name,
          description: request.description!,
          content: request.content!,
          imageName: request.imageName!,
        }}
        onChange={(article: Article) =>
          setRequest({
            name: article.name,
            description: article.description,
            content: article.content,
            imageName: article.imageName,
          })
        }
        imageNames={imageNamesResponse?.items ?? []}
        onSubmit={submit}
        loadingSubmit={loadingSubmit}
        buttonLabel="Vytvořit"
      />
    </>
  )
}
