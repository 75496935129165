import { Fragment, useEffect, useState } from "react"
import agent from "../../../common/api/agent"
import { MutualFunds_GetWithUnuploadedAnalysis_GetWithUnuploadedAnalysisResponse } from "../../../common/api/exportedModels"
import { ActionRowCard, CircularProgressPage, Spacer } from "@fastdo-react"

export default function MutualFundsWithUnuploadedAnalysis() {
  const [response, setResponse] =
    useState<MutualFunds_GetWithUnuploadedAnalysis_GetWithUnuploadedAnalysisResponse | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      try {
        var response = await agent.MutualFunds.getWithUnuploadedAnalysis()
        setResponse(response)
      } catch {}
      setLoading(false)
    }
    get()
  }, [])

  if (loading) return <CircularProgressPage />

  return (
    <>
      <h1>Fondy s nenahranou analýzou: {response?.items?.length}</h1>
      {response?.items!.map((item, index) => (
        <Fragment key={index}>
          <ActionRowCard id={item} label={item} />
          <Spacer />
        </Fragment>
      ))}
    </>
  )
}
