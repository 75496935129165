import GA4React from "ga-4-react"

// https://stackoverflow.com/questions/64623059/google-analytics-4-with-react
export const initGA = () => {
  if (process.env.NODE_ENV === "production") {
    try {
      setTimeout((_: any) => {
        const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!)
        ga4react.initialize().catch((err) => console.error(err))
      }, 4000)
    } catch (err) {
      console.error(err)
    }
  }
}
