import styles from "./TableItem.module.css"
import { useState } from "react"
import { toast } from "react-toastify"
import { MutualFunds_Get_GetMutualFundsItemResponse } from "common/api/exportedModels"
import agent from "common/api/agent"
import { Button } from "@fastdo-react"
import { Link } from "react-router-dom"

export interface Props {
  item: MutualFunds_Get_GetMutualFundsItemResponse
  searched: boolean
  opened: boolean
  isAdmin?: boolean
  onClick: (id: string | undefined) => void
  onHighlightClick: (item: MutualFunds_Get_GetMutualFundsItemResponse) => void
  onDeleteClick: (item: MutualFunds_Get_GetMutualFundsItemResponse) => void
  onArrangeOnlineClick: (item: MutualFunds_Get_GetMutualFundsItemResponse) => void
}

export default function TableItem({
  item,
  searched,
  opened,
  isAdmin = false,
  onClick,
  onHighlightClick,
  onDeleteClick,
  onArrangeOnlineClick,
}: Props) {
  const [loadingSetAsDefault, setLoadingSetAsDefault] = useState(false)

  const setAsDefault = async () => {
    if (!item.id) return
    setLoadingSetAsDefault(true)
    try {
      await agent.MutualFunds.setDefault(item.id)
      toast.success("Výchozí fond nastaven")
    } catch {}
    setLoadingSetAsDefault(false)
  }

  return (
    <>
      <tr
        className={styles.defaultTr}
        onClick={() => onClick(opened ? undefined : item.id)}
        style={{
          backgroundColor: searched ? "var(--fastdo-primary-color)" : "",
          color: searched ? "var(--fastdo-light-color)" : "",
        }}
      >
        <td style={{ textAlign: "center" }}>{item.order}</td>
        <td>{item.quality?.toFixed(3)}</td>
        <td style={{ maxWidth: "300px" }}>
          {item.name} <br /> {item.isin}
        </td>
        <td style={{ maxWidth: "200px" }}>{item.investmentCompany}</td>
        <td>{item.assetClass}</td>
        <td>{item.currency}</td>
        <td>{item.age?.toFixed(3)}</td>
        <td style={{ whiteSpace: "nowrap" }}>{item.expectedYield?.toFixed(3)} %</td>
        <td style={{ whiteSpace: "nowrap" }}>{item.worstHistoricalYield?.toFixed(3)} %</td>
      </tr>
      {opened && (
        <tr>
          <td colSpan={100}>
            <div className={styles.extendTrContainerDiv}>
              <div>
                <Link className="fastdo-button fastdo-button-default" to={`/analysis/${item.id}`}>
                  Analýza
                </Link>
              </div>
              <div>
                <Button label="Sjednat online" outlined onClick={() => onArrangeOnlineClick(item)} />
              </div>
              {isAdmin && (
                <>
                  <div>
                    <Button label="Zvýraznit" onClick={() => onHighlightClick(item)} />
                  </div>
                  <div>
                    <Button label="Nastavit jako výchozí" onClick={setAsDefault} loading={loadingSetAsDefault} />
                  </div>
                  <div>
                    <Button label="Odstranit" onClick={() => onDeleteClick(item)} />
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
