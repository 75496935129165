import styles from "./Publications.module.css"

export default function Publications() {
  return (
    <>
      <div className={styles.publicationsDiv}>
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-jednoduchy-navod-jak-si-nasporit-a-stat-se-bohatym-duchodcem-5972">
          Kvalitní dynamické fondy zajistí přilepšení k důchodu i desítky tisíc měsíčně
        </a>
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-jak-spolehlive-znicit-duchodovy-system-a-postvat-obcany-proti-sobe-diskuzi-o-narocnych-profesich-5970">
          Jak spolehlivě zničit důchodový systém a poštvat občany proti sobě? Diskuzí o náročných profesích
        </a>
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-podle-ministra-jurecky-zajisti-stabilitu-duchodoveho-systemu-vic-deti-hezky-ale-nerealny-plan-5765">
          Podle ministra Jurečky zajistí stabilitu důchodového systému víc dětí. Hezký, ale nereálný plán
        </a>
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-penzijko-je-nefunkcni-tak-tam-nacpeme-vic-penez-penzijni-spolecnosti-se-raduji-a-co-obcane-5608">
          Penzijko je nefunkční? Tak tam nacpeme víc peněz. Penzijní společnosti se radují. A co občané?
        </a>
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-duchody-zachrani-jen-kvalitni-sporeni-neni-to-ale-jen-chimera-5342">
          Důchody zachrání jen kvalitní spoření. Není to ale jen chiméra?
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-duchody-porostou-a-deficity-zamiri-k-nule-navzdory-tvrzenim-odborniku-5018">
          Důchody porostou a deficity zamíří k nule, navzdory tvrzením odborníků
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-do-duchodu-o-zebracke-holi-ci-v-71-nebo-radeji-pohodovy-duchod-o-to-se-dnes-hraje-4512">
          Do důchodu o žebrácké holi, či v 71? Nebo raději pohodový důchod? O to se dnes hraje
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-politicka-sebevrazda-v-primem-prenosu-nikoho-nepotesit-a-vetsinu-nastvat-3859">
          Politická sebevražda v přímém přenosu: nikoho nepotěšit a většinu naštvat
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/odbory-a-opozice-povazuji-duchodovy-system-za-neustavni-jeste-si-nevsimly-ze-je-predevsim-spatny-3655">
          Odbory a opozice považují důchodový systém za neústavní, ještě si nevšimly, že je především špatný
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/cesi-jsou-rozumni-a-spori-kapsu-si-ale-masti-jini-3412">
          Češi jsou rozumní a spoří, kapsu si ale mastí jiní
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-fantazie-ministra-jurecky-se-utrhla-ze-retezu-a-duchody-budou-konecne-ferove-3152">
          Fantazie ministra Jurečky se utrhla ze řetězu a důchody budou konečně férové
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-obcan-jako-hracka-v-rukach-politiku-kdy-se-politici-probudi-2835">
          Občan jako hračka v rukách politiků. Kdy se politici probudí?
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-potrebujeme-k-necemu-penzijni-spolecnosti-neni-to-slepa-ulicka-2642">
          Potřebujeme k něčemu penzijní společnosti? Není to slepá ulička?
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/penzijni-spolecnosti-loni-vydelaly-miliardu-jejich-klienti-byli-i-20-v-minusu-je-to-fer-2545">
          Penzijní společnosti loni vydělaly miliardu, jejich klienti byli i 20 % v minusu. Je to fér?
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/jak-cechum-vynasi-sporeni-1606">Jak Čechům (ne)vynáší spoření</a>
        <br />
        <a href="https://medium.seznam.cz/clanek/mytus-jmenem-volatilita-1605">Mýty v investování: volatilita</a>
        <br />
        <a href="https://medium.seznam.cz/clanek/rndr-stefan-novota-trychtyrova-metoda-porovnavani-kvality-podilovych-fondu-1599">
          Trychtýřová metoda porovnávání kvality podílových fondů
        </a>
        <br />
        <a href="https://medium.seznam.cz/clanek/kvalita-podilovych-fondu-jako-novy-pristup-k-investovani-1595">
          Kvalita podílových fondů jako nový přístup k investování
        </a>
      </div>
    </>
  )
}
