import { useEffect } from "react"
import Head from "react-helmet"
import Publications from "components/pages/about/Publications"
import Members from "components/pages/about/Members"
import HomeLayout from "@fastdo-react/layouts/HomeLayout"
import { Spacer } from "@fastdo-react"

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HomeLayout
      headProps={{
        HeadHelement: Head,
        title: "O nás - Srovnej investice",
        link: "https://srovnejinvestice.cz/about",
        imageCard: "https://srovnejinvestice.cz/assets/logos/logo-text.png",
      }}
      title="O nás"
    >
      <Members />
      <Spacer size={20} />

      <h1>Naše články v médiích</h1>
      <Publications />
    </HomeLayout>
  )
}
