import View from "./View"
import ChangePasswordAdminPage from "./main/ChangePasswordAdminPage"
import { LogoutPage } from "@fastdo-react"
import LoginAdminPage from "./main/LoginAdminPage"
import { Navigate } from "react-router-dom"

export const routes = {
  path: "auth",
  element: <View />,
  children: [
    { path: "login-admin", element: <LoginAdminPage /> },
    { path: "change-password-admin", element: <ChangePasswordAdminPage /> },
    { path: "logout", element: <LogoutPage navigateComponent={<Navigate to="/" />} /> },
  ],
}
