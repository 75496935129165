import styles from "./MutualFundDetailCard.module.css"
import { MutualFunds_GetDetail_GetDetailResponse } from "../../common/api/exportedModels"
import { Card, HrSpacer, PdfDownloadRowItem } from "@fastdo-react"
import { useEffect, useState } from "react"

export interface Props {
  mutualFund: MutualFunds_GetDetail_GetDetailResponse
}

export default function MutualFundDetailCard({ mutualFund }: Props) {
  const [pdfValidArray, setPdfValidArray] = useState<any[]>([])

  useEffect(() => {
    const pdfValidArrayBuffer = []
    if (mutualFund.fundStatus) pdfValidArrayBuffer.push({ name: "Status fondu", url: mutualFund.fundStatus })
    if (mutualFund.kiid) pdfValidArrayBuffer.push({ name: "KIID", url: mutualFund.kiid })
    if (mutualFund.factsheet) pdfValidArrayBuffer.push({ name: "Factsheet", url: mutualFund.factsheet })

    setPdfValidArray(pdfValidArrayBuffer)
  }, [mutualFund.fundStatus, mutualFund.kiid, mutualFund.factsheet, setPdfValidArray])

  return (
    <Card>
      <h3>{mutualFund.name}</h3>
      <div className={styles.contentDiv}>
        <ul>
          <li className={styles.itemHeader}>Pořadí</li>
          <li className={styles.itemText}>{mutualFund.order}</li>
        </ul>
        <HrSpacer />
        <ul>
          <li className={styles.itemHeader}>Kvalita</li>
          <li className={styles.itemText}>{mutualFund.quality!.toFixed(3).replace(".", ",")}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Průměrný výnos</li>
          <li className={styles.itemText}>{mutualFund.expectedYield!.toFixed(3).replace(".", ",")} %</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Nejnižší výnos</li>
          <li className={styles.itemText}>{mutualFund.worstHistoricalYield!.toFixed(3).replace(".", ",")} %</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Investiční společnost</li>
          <li className={styles.itemText}>{mutualFund.investmentCompany}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>ISIN</li>
          <li className={styles.itemText}>{mutualFund.isin}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Třída aktiv</li>
          <li className={styles.itemText}>{mutualFund.assetClass}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Měna</li>
          <li className={styles.itemText}>{mutualFund.currency}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Regionální zaměření</li>
          <li className={styles.itemText}>{mutualFund.regionalFocus}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Stáří fondu</li>
          <li className={styles.itemText}>{mutualFund.age!.toFixed(3).replace(".", ",")}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Doporučený investiční horizont</li>
          <li className={styles.itemText}>{mutualFund.nonNegativeReturnsFrom!.toFixed(3).replace(".", ",")}</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Poplatky za správu</li>
          <li className={styles.itemText}>{mutualFund.managementFees?.toString().replace(".", ",")} %</li>
        </ul>
        <HrSpacer />{" "}
        <ul>
          <li className={styles.itemHeader}>Korelace s akciovým trhem</li>
          <li className={styles.itemText}>{mutualFund.correlation!.toFixed(3).replace(".", ",")}</li>
        </ul>
        <hr style={{ height: "1px", opacity: "1" }} />
        {pdfValidArray.map((item, index) => (
          <div key={index} style={{ padding: "0.4rem 0" }}>
            <PdfDownloadRowItem name={item.name} pdfUrl={item.url} />
          </div>
        ))}
      </div>
    </Card>
  )
}
