import { toast } from "react-toastify"
import agent from "../../../common/api/agent"
import { ButtonWithAsk, FileUploadCard, Spacer } from "@fastdo-react"

export default function MutualFundsAdminCard() {
  const uploadFile = async (file: File) => {
    await agent.MutualFunds.uploadCsv(file)
  }

  const deleteAll = async () => {
    try {
      await agent.MutualFunds.deleteAll()
      window.location.reload()
      toast.success("Úspěšně odstraněno")
    } catch {}
  }

  return (
    <>
      <FileUploadCard
        fileSelectorLabel="Nahrajte .csv soubor s podílovými fondy"
        acceptedFileTypes={[".csv"]}
        onUpload={uploadFile}
      />
      <Spacer />

      <ButtonWithAsk
        buttonProps={{ label: "Odstranit vše", danger: true }}
        actionWithAskProps={{ title: "Odstranit vše", onAccepted: deleteAll }}
      />
    </>
  )
}
