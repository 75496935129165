import { useState, useEffect } from "react"
import { Articles_Edit_EditRequest, MediaImages_GetNames_GetNamesResponse } from "../../../common/api/exportedModels"
import { CircularProgressPage } from "@fastdo-react"
import agent from "common/api/agent"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ActionArticleCard, { Article } from "./ActionArticleCard"

export interface Props {
  nameUrl: string
}

export default function EditArticleCard({ nameUrl }: Props) {
  const [imageNamesResponse, setImageNamesResponse] = useState<MediaImages_GetNames_GetNamesResponse | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState<string>("")
  const [request, setRequest] = useState<Articles_Edit_EditRequest>({
    name: "",
    imageName: "",
    description: "",
    content: "",
  })
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      try {
        var imageNamesResponse = await agent.MediaImages.getNames()
        imageNamesResponse.items?.sort()
        setImageNamesResponse(imageNamesResponse)
        var articleResponse = await agent.Articles.getDetail(nameUrl)
        setRequest({
          name: articleResponse.name!,
          imageName: articleResponse.imageName,
          description: articleResponse.description,
          content: articleResponse.content,
        })
        setId(articleResponse.id!)
      } catch {}
      setLoading(false)
    }
    get()
  }, [nameUrl])

  const navigate = useNavigate()

  const submit = async () => {
    setLoadingSubmit(true)
    try {
      await agent.Articles.edit(id, request)
      toast.success("Úspěšně vytvořeno")
      navigate("/admin/articles")
    } catch {}
    setLoadingSubmit(false)
  }

  if (loading) return <CircularProgressPage />

  return (
    <>
      <ActionArticleCard
        article={{
          name: request.name,
          description: request.description!,
          content: request.content!,
          imageName: request.imageName!,
        }}
        onChange={(article: Article) =>
          setRequest({
            name: article.name,
            description: article.description,
            content: article.content,
            imageName: article.imageName,
          })
        }
        imageNames={imageNamesResponse?.items ?? []}
        onSubmit={submit}
        loadingSubmit={loadingSubmit}
        buttonLabel="Upravit"
      />
    </>
  )
}
