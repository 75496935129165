import { useEffect } from "react"
import { HomeLayout } from "@fastdo-react"
import Head from "react-helmet"
import ArticleList from "components/pages/articles/ArticleList"

export default function ArticlesPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HomeLayout
      headProps={{
        HeadHelement: Head,
        title: "Články - Srovnej investice",
        link: "https://srovnejinvestice.cz/articles",
        imageCard: "https://srovnejinvestice.cz/assets/logos/logo-text.png",
      }}
      title="Články"
    >
      <ArticleList />
    </HomeLayout>
  )
}
