import styles from "./Footer.module.css"
import { Link } from "react-router-dom"
import { Spacer } from "@fastdo-react"

export interface Props {
  onCookiesSettingsClick?: () => void
}
export default function Footer({ onCookiesSettingsClick = () => {} }: Props) {
  return (
    <footer className={styles.component}>
      <address>
        KOMPAS Investment
        <br />
        IČ: 01473476
        <br />
        Purkyňova 920, 46001 Liberec
        <br />
        <a href="mailto: info@srovnejinvestice.cz">info@srovnejinvestice.cz</a>
      </address>
      <Spacer />

      <p style={{ margin: "0" }}>+420 775 646 625 - Štefan Novota </p>
      <p style={{ margin: "0" }}>+420 602 402 716 - Petr Novota</p>
      <Spacer />

      <p style={{ margin: "0" }}>Informace na webu nejsou investičním doporučením ani investičním poradenstvím.</p>

      <Spacer />
      <Link to="/cookies" className={styles.dataProtectionLink}>
        Ochrana osobních údajů
      </Link>
      <Spacer />

      <p style={{ margin: "0" }} className={styles.cookiesSettingsP} onClick={onCookiesSettingsClick}>
        Nastavení cookies
      </p>
      <Spacer />

      <p style={{ margin: "0" }}>&copy; Copyright 2023</p>
    </footer>
  )
}
