import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import store from "common/store"
import "./styles/variables.css"
import "@fastdo-react/styles/index.css"
import "@fastdo-react/components/quill/quill.css"
import "katex/dist/katex.min.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
