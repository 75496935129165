import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { useAppDispatch } from "./common/storeHooks"
import { getAppData } from "./common/slices/appDataSlice"
import NotFoundView from "./views/error/NotFoundView"
import { useEffect } from "react"
import styles from "./App.module.css"
import { routes as homeRoutes } from "./views/home/routes"
import { routes as authRoutes } from "./views/auth/routes"
import { routes as adminRoutes } from "./views/admin/routes"

export default function App() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAppData())
  }, [dispatch])

  const router = createBrowserRouter([
    homeRoutes,
    authRoutes,
    adminRoutes,
    { path: "/404", element: <NotFoundView /> },
    { path: "*", element: <Navigate to="/404" /> },
  ])

  return (
    <div className={styles.component}>
      <ToastContainer style={{ top: "5rem" }} />
      <RouterProvider router={router} />
    </div>
  )
}
