import { configureStore } from "@reduxjs/toolkit"
import appDataSlice from "./slices/appDataSlice"
import cookiesSlice from "./slices/cookiesSlice"

const store = configureStore({
  reducer: {
    appData: appDataSlice,
    cookies: cookiesSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
