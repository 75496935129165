import GA4React from "ga-4-react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import agent from "../../common/api/agent"
import { analyticalCookiesAcceptedKey, Button, CenterModal, getAccept, Spacer, TextField } from "@fastdo-react"

export interface Props {
  onClose: () => void
}

export default function InProgressModal({ onClose }: Props) {
  const [email, setEmail] = useState("")
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useEffect(() => {
    if (getAccept(analyticalCookiesAcceptedKey)) {
      try {
        setTimeout((_: any) => {
          const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!)
          ga4react.event("Sejdnat online klik", "Sejdnat online klik", "Sejdnat online klik")
        }, 4000)
      } catch (err) {
        console.error(err)
      }
    }
  }, [])

  const submit = async () => {
    setLoadingSubmit(true)
    try {
      await agent.InvestingInterests.addEmail({
        email: email,
      })
      toast.success("Děkujeme za váš zájem")
      onClose()
    } catch {}

    setEmail("")
    setLoadingSubmit(false)
  }

  return (
    <CenterModal show onShaderClick={onClose} style={{ maxWidth: "400px" }}>
      <p style={{ textAlign: "start" }}>
        Děkujeme za váš zájem, na této službě momentálně pracujeme. Pokud máte zájem abychom vám dali vědět až bude
        produkt hotov, tak nám můžete na sebe zanechat email.
      </p>
      <Spacer />

      <TextField
        type="email"
        placeholder="Zadejte email"
        value={email}
        onChange={(value) => setEmail(value)}
        onEnter={() => submit()}
      />
      <Spacer size={20} />

      <div style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
        <Button label="Odeslat" onClick={() => submit()} loading={loadingSubmit} />
        <div style={{ width: "10px" }}></div>
        <Button label="Zavřít" outlined={true} onClick={onClose} />
      </div>
    </CenterModal>
  )
}
