import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Head from "react-helmet"
import { CircularProgressPage, HomeLayout, HrSpacer, QuillDisplayer, Spacer } from "@fastdo-react"
import { Articles_GetDetail_GetDetailResponse } from "common/api/exportedModels"
import agent from "common/api/agent"

export default function ArticlesDetailPage() {
  let { nameUrl } = useParams()

  const [response, setResponse] = useState<Articles_GetDetail_GetDetailResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const get = async () => {
      if (!nameUrl) return
      setLoading(true)
      try {
        var response = await agent.Articles.getDetail(nameUrl)
        setResponse(response)
      } catch {}
      setLoading(false)
    }
    window.scrollTo(0, 0)
    get()
  }, [nameUrl])

  if (loading) return <CircularProgressPage />

  return (
    <HomeLayout
      headProps={{
        HeadHelement: Head,
        title: `${response?.name} - Srovnej investice`,
        link: `https://srovnejinvestice.cz/articles/${response?.nameUrl}`,
        imageCard: `https://srovnejinvestice.cz/api/MediaImages/GetSingle/${response?.imageName}`,
      }}
      title={response?.name}
    >
      <p className="fastdo-label">Vytvořeno: {new Date(response?.lastUpdated!).toLocaleDateString("cs-CZ")}</p>
      <Spacer />
      <p>{response?.description}</p>
      <Spacer />
      <HrSpacer />
      <Spacer />

      <QuillDisplayer content={response?.content!} />
    </HomeLayout>
  )
}
