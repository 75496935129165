import {
  MutualFunds_Get_GetMutualFundsFilterRequest,
  AppData_Get_GetMutualFundItemResponse,
} from "common/api/exportedModels"
import { useState } from "react"
import {
  compareStringsInSearch,
  RecommendationTextField as FastDoRecommendationTextField,
  stringIsNullOrWhiteSpaces,
} from "@fastdo-react"
import { RecommendationItem } from "@fastdo-react/components/form/text/RecommendationTextField"
import { useAppSelector } from "common/storeHooks"

export interface Props {
  filter?: MutualFunds_Get_GetMutualFundsFilterRequest
  label?: string
  onRecommendationClick: (id: string) => void
}

export default function RecommendationTextField({ filter = undefined, label = "", onRecommendationClick }: Props) {
  const mutalFunds = useAppSelector((state) => state.appData.response?.mutualFunds)

  const [value, setValue] = useState("")
  const [recommendations, setRecommendations] = useState<AppData_Get_GetMutualFundItemResponse[]>([])

  const getMutualFunds = () => {
    if (!mutalFunds) return []
    if (!filter) return mutalFunds

    var newMutalFunds = mutalFunds
    if (filter.investmentCompanies && filter.investmentCompanies.length > 0)
      newMutalFunds = newMutalFunds.filter((item) => filter.investmentCompanies?.includes(item.investmentCompany ?? ""))
    if (filter.assetClasses && filter.assetClasses.length > 0)
      newMutalFunds = newMutalFunds.filter((item) => filter.assetClasses?.includes(item.assetClass ?? ""))
    if (filter.currencies && filter.currencies.length > 0)
      newMutalFunds = newMutalFunds.filter((item) => filter.currencies?.includes(item.currency ?? ""))
    if (filter.regionalFocuses && filter.regionalFocuses.length > 0)
      newMutalFunds = newMutalFunds.filter((item) => filter.regionalFocuses?.includes(item.regionalFocus ?? ""))
    if (filter.expectedYieldRange && filter.expectedYieldRange.from && filter.expectedYieldRange.to)
      newMutalFunds = newMutalFunds.filter(
        (item) =>
          filter.expectedYieldRange!.from! <= (item.expectedYield ?? 0) &&
          (item.expectedYield ?? 0) <= filter.expectedYieldRange!.to!,
      )
    return newMutalFunds
  }

  const handleValueChange = (value: string) => {
    setValue(value)
    if (stringIsNullOrWhiteSpaces(value)) setRecommendations(getMutualFunds())
    else
      setRecommendations(
        getMutualFunds().filter((item) => compareStringsInSearch(item.name ?? "", value) || item.isin?.includes(value)),
      )
  }

  const handlerRecommendationClick = (item: RecommendationItem) => {
    setRecommendations([])
    setValue("")
    onRecommendationClick(item.id)
  }

  const handleInputClick = () => {
    if (stringIsNullOrWhiteSpaces(value)) setRecommendations(getMutualFunds())
  }

  return (
    <FastDoRecommendationTextField
      textFieldProps={{
        value: value,
        onChange: (value) => handleValueChange(value),
        placeholder: "Hledat fond",
        label: label,
      }}
      onInputClick={handleInputClick}
      recommendations={recommendations?.map((item) => ({ id: item.isin!, name: item.name! }))}
      onRecommendationClick={handlerRecommendationClick}
    />
  )
}
