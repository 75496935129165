import { useEffect, useState } from "react"
import styles from "./FilterCard.module.css"
import {
  MutualFunds_GetFilterValues_GetExpectedYieldRangeResponse,
  MutualFunds_GetFilterValues_GetFilterValuesResponse,
  MutualFunds_Get_GetMutualFundsFilterRequest,
} from "common/api/exportedModels"
import agent from "common/api/agent"
import { Button, PrimaryCircularProgress, PrimarySlider, TextSelect } from "@fastdo-react"

export interface Props {
  filter: MutualFunds_GetFilterValues_GetFilterValuesResponse
  onFilterChange: (filter: MutualFunds_Get_GetMutualFundsFilterRequest) => void
  onClose: () => void
}

export const defaultFilter: MutualFunds_Get_GetMutualFundsFilterRequest = {
  investmentCompanies: [],
  assetClasses: [],
  currencies: [],
  regionalFocuses: [],
}

export default function FilterCard({ filter, onFilterChange, onClose }: Props) {
  const [response, setResponse] = useState<MutualFunds_GetFilterValues_GetFilterValuesResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const [expectedYieldRange, setExpectedYieldRange] = useState<
    MutualFunds_GetFilterValues_GetExpectedYieldRangeResponse | undefined
  >(undefined)

  useEffect(() => {
    const getFilter = async () => {
      setLoading(true)
      var response = await agent.MutualFunds.getFilterValues()
      setResponse(response)
      onFilterChange({
        ...filter,
        expectedYieldRange: {
          from: response.expectedYieldRange?.from,
          to: response.expectedYieldRange?.to,
        },
      })
      setExpectedYieldRange({
        from: response.expectedYieldRange?.from,
        to: response.expectedYieldRange?.to,
      })
      setLoading(false)
    }
    getFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    loading ||
    !response ||
    !response.investmentCompanies ||
    !response.assetClasses ||
    !response.currencies ||
    !response.regionalFocuses ||
    !response.expectedYieldRange ||
    !expectedYieldRange
  )
    return (
      <div className={styles.loadingDiv}>
        <PrimaryCircularProgress size={80} />
      </div>
    )

  return (
    <div className={styles.component}>
      <div className={styles.selectorsBoxContainer}>
        <div className={styles.selectorContainer}>
          <p className="fastdo-label">Investiční společnost</p>
          <TextSelect
            isMulti
            options={response!.investmentCompanies!.map((x) => ({ value: x, label: x }))}
            selected={filter.investmentCompanies!.map((x) => ({ value: x, label: x }))}
            onChange={(value: any[]) =>
              onFilterChange({
                ...filter,
                investmentCompanies: value.map((x) => x.value),
              })
            }
          />
        </div>

        <div className={styles.selectorContainer}>
          <p className="fastdo-label">Třída aktiv</p>
          <TextSelect
            isMulti
            options={response!.assetClasses!.map((x) => ({ value: x, label: x }))}
            selected={filter.assetClasses!.map((x) => ({ value: x, label: x }))}
            onChange={(value: any[]) =>
              onFilterChange({
                ...filter,
                assetClasses: value.map((x) => x.value),
              })
            }
          />
        </div>

        <div className={styles.selectorContainer}>
          <p className="fastdo-label">Měna</p>
          <TextSelect
            isMulti
            options={response!.currencies!.map((x) => ({ value: x, label: x }))}
            selected={filter.currencies!.map((x) => ({ value: x, label: x }))}
            onChange={(value: any[]) =>
              onFilterChange({
                ...filter,
                currencies: value.map((x) => x.value),
              })
            }
          />
        </div>

        <div className={styles.selectorContainer}>
          <p className="fastdo-label">Regionální zaměření</p>
          <TextSelect
            isMulti
            options={response!.regionalFocuses!.map((x) => ({ value: x, label: x }))}
            selected={filter.regionalFocuses!.map((x) => ({ value: x, label: x }))}
            onChange={(value: any[]) =>
              onFilterChange({
                ...filter,
                regionalFocuses: value.map((x) => x.value),
              })
            }
          />
        </div>
      </div>

      <div className={styles.sliderContainer}>
        <p className="fastdo-label">Průměrný výnos</p>
        <div style={{ padding: "0 1rem" }}>
          <PrimarySlider
            min={response!.expectedYieldRange!.from!}
            max={response!.expectedYieldRange!.to!}
            value={[expectedYieldRange!.from!, expectedYieldRange!.to!]}
            onChange={(value) => {
              if (value) {
                var arr = value as number[]
                if (arr.length > 1)
                  setExpectedYieldRange({
                    from: arr[0],
                    to: arr[1],
                  })
              }
            }}
            onChangeCommitted={() =>
              onFilterChange({
                ...filter,
                expectedYieldRange: expectedYieldRange,
              })
            }
          />
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <div className={styles.buttonItem}>
          <Button label="Reset" onClick={() => onFilterChange(defaultFilter)} />
        </div>
        <div className={styles.buttonItem}>
          <Button label="Zavřít" onClick={onClose} outlined={true} />
        </div>
      </div>
    </div>
  )
}
