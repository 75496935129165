import { downloadFile, redirect, requestsAxios } from "@fastdo-react"
import {
  SuperadminUsers_Login_LoginRequest,
  SuperadminUsers_Login_LoginResponse,
  SuperadminUsers_ChangePassword_ChangePasswordRequest,
  SuperadminUsers_CheckStatus_CheckStatusResponse,
  MutualFunds_GetFilterValues_GetFilterValuesResponse,
  MutualFunds_Get_GetRequest,
  MutualFunds_Get_GetResponse,
  MediaImages_GetNames_GetNamesResponse,
  Articles_Create_CreateRequest,
  Articles_GetDetail_GetDetailResponse,
  Articles_Get_GetResponse,
  Articles_Edit_EditRequest,
  AnalysisMutualFunds_GetComparisonData_GetComparisonDataRequest,
  AnalysisMutualFunds_GetComparisonData_GetComparisonDataResponse,
  MutualFunds_GetDetail_GetDetailResponse,
  MutualFunds_GetRecommendation_GetRecommendationResponse,
  MutualFunds_GetRecommendation_GetRecommendationRequest,
  InvestingInterests_Get_GetResponse,
  InvestingInterests_AddEmail_AddEmailRequest,
  InvestingInterests_DeleteEmail_DeleteEmailRequest,
  MutualFunds_GetWithUnuploadedAnalysis_GetWithUnuploadedAnalysisResponse,
  MutualFunds_GetScatterData_GetScatterDataResponse,
  ScatterHighlights_Get_GetResponse,
  ScatterHighlights_Add_AddRequest,
  ScatterHighlights_Edit_EditRequest,
  AppData_Get_GetResponse,
} from "./exportedModels"
import { config } from "@fastdo-react/api/baseAgentAxios"
import { toast } from "react-toastify"

const { get, post, put, del } = requestsAxios

config.on401 = (message: string | undefined, showToast: boolean, push: boolean) => {
  if (showToast) toast.error(message)
  if (push) redirect("/404")
}

config.on403 = (message: string | undefined, showToast: boolean, push: boolean) => {
  if (showToast) toast.error(message)
  if (push) redirect("/404")
}

config.onErrorDefault = (message: string | undefined, showToast: boolean, push: boolean) => {
  if (showToast) toast.error(message)
}

const Articles = {
  create: (request: Articles_Create_CreateRequest) => post<void>("/Articles/Create", request),
  delete: (id: string) => del(`/Articles/Delete/${id}`),
  deleteAll: () => del(`/Articles/DeleteAll`),
  edit: (id: string, request: Articles_Edit_EditRequest) => put<void>(`/Articles/Edit/${id}`, request),
  get: (params: URLSearchParams) => get<Articles_Get_GetResponse>("/Articles/Get/", params),
  getDetail: (nameUrl: string) => get<Articles_GetDetail_GetDetailResponse>(`/Articles/GetDetail/${nameUrl}`),
  moveUp: (id: string) => put<void>(`/Articles/MoveUp/${id}`),
  moveDown: (id: string) => put<void>(`/Articles/MoveDown/${id}`),
  getCsv: async () => {
    var data = await get<any>("/Articles/GetCsv")
    downloadFile(data, "articles.csv")
  },
  uploadCsv: (file: File) => {
    var formData = new FormData()
    formData.append("file", file)
    return post<void>("/Articles/UploadCsv", formData, { "Content-Type": "multipart/form-data" })
  },
}

const MediaImages = {
  delete: (id: string) => del(`/MediaImages/Delete/${id}`),
  deleteAll: () => del(`/MediaImages/DeleteAll`),
  getNames: () => get<MediaImages_GetNames_GetNamesResponse>("/MediaImages/GetNames"),
  upload: (file: File) => {
    var formData = new FormData()
    formData.append("file", file)
    return post<void>("/MediaImages/Upload", formData, { "Content-Type": "multipart/form-data" }, false)
  },
}

const MutualFunds = {
  get: (body: MutualFunds_Get_GetRequest) => post<MutualFunds_Get_GetResponse>("/MutualFunds/Get", body),
  getScatterData: () => get<MutualFunds_GetScatterData_GetScatterDataResponse>("/MutualFunds/GetScatterData"),
  getFilterValues: () => get<MutualFunds_GetFilterValues_GetFilterValuesResponse>("/MutualFunds/GetFilterValues"),
  getDetail: (id: string) => get<MutualFunds_GetDetail_GetDetailResponse>(`/MutualFunds/GetDetail/${id}`),
  getDefaultDetail: () => get<MutualFunds_GetDetail_GetDetailResponse>("/MutualFunds/GetDefaultDetail"),
  getFirstOrderDetail: () => get<MutualFunds_GetDetail_GetDetailResponse>("/MutualFunds/GetFirstOrderDetail"),
  getRecommendation: (body: MutualFunds_GetRecommendation_GetRecommendationRequest) =>
    post<MutualFunds_GetRecommendation_GetRecommendationResponse>("/MutualFunds/GetRecommendation", body),
  uploadCsv: (file: File) => {
    var formData = new FormData()
    formData.append("file", file)
    return post<void>("/MutualFunds/UploadCsv", formData, { "Content-Type": "multipart/form-data" }, false)
  },
  setDefault: (id: string) => put(`/MutualFunds/SetDefault/${id}`, {}),
  deleteSingle: (id: string) => del(`/MutualFunds/DeleteSingle/${id}`),
  deleteAll: () => del(`/MutualFunds/DeleteAll`),
  getWithUnuploadedAnalysis: () =>
    get<MutualFunds_GetWithUnuploadedAnalysis_GetWithUnuploadedAnalysisResponse>(
      `/MutualFunds/GetWithUnuploadedAnalysis`,
    ),
}

const ScatterHighlights = {
  get: () => get<ScatterHighlights_Get_GetResponse>("/ScatterHighlights/Get"),
  add: (request: ScatterHighlights_Add_AddRequest) => post<void>("/ScatterHighlights/Add", request),
  edit: (id: string, request: ScatterHighlights_Edit_EditRequest) =>
    put<void>(`/ScatterHighlights/Edit/${id}`, request),
  delete: (id: string) => del(`/ScatterHighlights/Delete/${id}`),
}

const AnalysisMutualFunds = {
  getComparisonData: (body: AnalysisMutualFunds_GetComparisonData_GetComparisonDataRequest) =>
    post<AnalysisMutualFunds_GetComparisonData_GetComparisonDataResponse>(
      "/AnalysisMutualFunds/getComparisonData",
      body,
      {},
      false,
    ),
  uploadCsv: (file: File) => {
    var formData = new FormData()
    formData.append("file", file)
    return post<void>("/AnalysisMutualFunds/UploadCsv", formData, { "Content-Type": "multipart/form-data" }, false)
  },
}

const InvestingInterests = {
  addEmail: (request: InvestingInterests_AddEmail_AddEmailRequest) =>
    post<void>("/InvestingInterests/AddEmail", request),
  get: () => get<InvestingInterests_Get_GetResponse>("/InvestingInterests/Get"),
  deleteEmail: (request: InvestingInterests_DeleteEmail_DeleteEmailRequest) =>
    post<void>("/InvestingInterests/DeleteEmail", request),
  deleteAll: () => del<void>(`/InvestingInterests/DeleteAll`),
  getCsv: async () => {
    var data = await get<any>("/InvestingInterests/GetCsv")
    downloadFile(data, "emails.csv")
  },
}

const SuperAdminUsers = {
  login: (request: SuperadminUsers_Login_LoginRequest) =>
    post<SuperadminUsers_Login_LoginResponse>("/SuperadminUsers/Login", request),
  changePassword: (request: SuperadminUsers_ChangePassword_ChangePasswordRequest) =>
    post<void>("/SuperadminUsers/ChangePassword", request),
  checkStatus: () => get<SuperadminUsers_CheckStatus_CheckStatusResponse>("/SuperadminUsers/CheckStatus"),
}

const AppData = {
  get: () => get<AppData_Get_GetResponse>("/AppData/Get"),
}

const agent = {
  Articles,
  MediaImages,
  MutualFunds,
  ScatterHighlights,
  AnalysisMutualFunds,
  InvestingInterests,
  SuperAdminUsers,
  AppData,
}

export default agent
