import styles from "./HomePage.module.css"
import ScatterPlot from "components/mutalFunds/plots/ScatterPlot"
import { useAppSelector } from "common/storeHooks"
import PrimaryHelmet from "@fastdo-react/components/yolo/PrimaryHelmet"
import Head from "react-helmet"
import { HrSpacer, Spacer } from "@fastdo-react"
import HomeTiles from "components/pages/home/HomeTiles"
import HomeTitle from "components/pages/home/HomeTitle"
import { useRef, useState } from "react"
import { MutualFunds_Get_GetMutualFundsItemResponse } from "common/api/exportedModels"

export default function HomePage() {
  const appDataSlice = useAppSelector((state) => state.appData)

  const tilesRef = useRef<any>(null)
  const executeScrollToTiles = () => tilesRef.current.scrollIntoView({ behavior: "smooth" })

  const [selectedMutualFund, setSelectedMutualFund] = useState<MutualFunds_Get_GetMutualFundsItemResponse | undefined>(
    undefined,
  )

  return (
    <div className={styles.component}>
      <PrimaryHelmet
        HeadHelement={Head}
        title="Srovnej investice | Dáváme investicím správný směr"
        link="https://srovnejinvestice.cz"
        imageCard="https://srovnejinvestice.cz/assets/logos/logo-text.png"
      />

      <HomeTitle />
      <Spacer size={20} />

      <ScatterPlot
        mutualFundsCount={appDataSlice.response?.mutalFundsCount}
        onWantKnowMoreClick={executeScrollToTiles}
        selectedMutualFund={selectedMutualFund}
        onSelectedMutualFundChange={setSelectedMutualFund}
      />
      <Spacer size={20} />
      <HrSpacer />
      <div ref={tilesRef}></div>

      <HomeTiles
        mutualFundsCount={appDataSlice.response?.mutalFundsCount ?? 0}
        selectedMutualFund={selectedMutualFund}
      />
    </div>
  )
}
