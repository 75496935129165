import { useEffect, useState } from "react"
import { ButtonWithAsk, CircularProgressPage, MediaImageRowCard, MultipleUploadCard, Spacer } from "@fastdo-react"
import { MediaImages_GetNames_GetNamesResponse } from "common/api/exportedModels"
import agent from "common/api/agent"
import { toast } from "react-toastify"

export default function MediaImages() {
  const [response, setResponse] = useState<MediaImages_GetNames_GetNamesResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingDeleteName, setLoadingDeleteName] = useState<string | undefined>(undefined)

  const get = async () => {
    setLoading(true)
    try {
      var response = await agent.MediaImages.getNames()
      setResponse(response)
    } catch {}
    setLoading(false)
  }

  useEffect(() => {
    get()
  }, [])

  const del = async (name: string) => {
    setLoadingDeleteName(name)
    try {
      await agent.MediaImages.delete(name)
      setResponse((prev) => {
        if (!prev || !prev.items) return null
        return {
          ...prev,
          items: prev.items.filter((item) => item !== name),
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
    setLoadingDeleteName(undefined)
  }

  const deleteAll = async () => {
    try {
      await agent.MediaImages.deleteAll()
      setResponse((prev) => {
        if (!prev || !prev.items) return null
        return {
          ...prev,
          items: [],
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
  }

  return (
    <>
      <MultipleUploadCard
        fileSelectorLabel="Nahrajte obrázky"
        acceptedFileTypes={[".jpg", ".png", ".jpeg"]}
        onUpload={(file) => agent.MediaImages.upload(file!)}
      />
      <Spacer />

      <ButtonWithAsk
        buttonProps={{ label: "Odstranit vše", danger: true }}
        actionWithAskProps={{ title: "Odstranit vše", onAccepted: deleteAll }}
      />
      <Spacer />

      {loading ? (
        <CircularProgressPage />
      ) : (
        <>
          {response?.items?.map((item: string, index: number) => (
            <MediaImageRowCard
              key={index}
              name={item}
              imgUrl={process.env.REACT_APP_API_URL + "/MediaImages/GetSingle/" + encodeURIComponent(item)}
              onDelete={del}
              loadingDelete={loadingDeleteName === item}
            />
          ))}
        </>
      )}
    </>
  )
}
