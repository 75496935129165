import { setUser } from "@fastdo-react"
import agent from "common/api/agent"
import { LoginAdminPage as FastDoLoginAdminPage } from "@fastdo-react"
import { useNavigate } from "react-router-dom"

export default function LoginAdminPage() {
  const navigate = useNavigate()

  const login = async (email: string, password: string) => {
    try {
      var response = await agent.SuperAdminUsers.login({
        email: email,
        password: password,
      })

      if (response.token) {
        setUser(response.token, "Superadmin")
        navigate("/admin")
      }
    } catch (error) {}
  }

  return <FastDoLoginAdminPage onSubmit={(email, password) => login(email, password)} />
}
