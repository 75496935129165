import { NavbarOld } from "@fastdo-react"
import { NavButtonItem, NavItem } from "@fastdo-react/components/nav-bar/NavbarOld"
import { Link, Outlet } from "react-router-dom"
import "react-quill/dist/quill.snow.css"
import IsLoggedIn from "components/general/IsLoggedIn"

export default function View() {
  const navItems: NavItem[] = [
    { to: "/admin/mutualfunds", text: "Podílové fondy" },
    { to: "/admin/highlights", text: "Zvýraznění" },
    { to: "/admin/analysis", text: "Analýza" },
    { to: "/admin/articles", text: "Články" },
    { to: "/admin/media", text: "Média" },
  ]

  const navButtonItems: NavButtonItem[] = [
    { to: "/", text: "Web", outlined: false },
    { to: "/auth/change-password-admin", text: "Změnit heslo", outlined: true },
    { to: "/auth/logout", text: "Odhlásit se", outlined: true },
  ]

  return (
    <>
      <IsLoggedIn />

      <NavbarOld
        items={navItems}
        buttonItems={navButtonItems}
        homeItem={{ to: "/admin", text: "Admin" }}
        navTopDefault={-40}
        LinkElement={Link}
      />

      <Outlet />
    </>
  )
}
