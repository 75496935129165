import { Link, Outlet } from "react-router-dom"
import Footer from "../../components/general/Footer"
import InProgressModal from "../../components/general/InProgressModal"
import { useEffect, useState } from "react"
import agent from "../../common/api/agent"
import NavbarOld, { MenuType, NavButtonItem, NavItem } from "@fastdo-react/components/nav-bar/NavbarOld"
import { CookieConsent, clearAll, isLoggedIn } from "@fastdo-react"
import { useAppDispatch, useAppSelector } from "common/storeHooks"
import { setAllAccepted, setSet, setShowSettings } from "common/slices/cookiesSlice"
import CookieSettings from "@fastdo-react/components/yolo/CookieSettings"
import { initGA } from "common/services/googleAnalytics"

export default function View() {
  const navItems: NavItem[] = [
    { to: "/articles", text: "Články" },
    { to: "/analysis", text: "Srovnávač" },
    { to: "/about", text: "O nás" },
  ]

  const navButtonItems: NavButtonItem[] = [
    { to: "/", text: "Chci investovat", outlined: false, onClick: () => setShowInProgressModal(true) },
  ]

  const cookiesSlice = useAppSelector((state) => state.cookies)
  const dispatch = useAppDispatch()

  const [showInProgressModal, setShowInProgressModal] = useState(false)

  useEffect(() => {
    const checkStatus = async () => {
      try {
        if (isLoggedIn()) {
          var statusResponse = await agent.SuperAdminUsers.checkStatus()
          if (!statusResponse.isValid) clearAll()
        }
      } catch {}
    }

    checkStatus()
  }, [])

  useEffect(() => {
    if (cookiesSlice.analyticalAccepted) {
      initGA()
    }
  }, [cookiesSlice.analyticalAccepted])

  return (
    <div>
      <NavbarOld
        items={navItems}
        buttonItems={navButtonItems}
        homeItem={{ to: "/", text: "Srovnej investice" }}
        // lightRoutes={["^/?$", "^/articles/(.*?)$"]}
        // lightRoutes={["^/articles/(.*?)$"]}
        homeLogo={process.env.PUBLIC_URL + "/assets/logos/logo-text.svg"}
        homeLogoLink="/"
        homeLogoTop={1}
        homeLogoLeft={1}
        homeLogoMaxWidth={200}
        menuType={MenuType.Flex}
        LinkElement={Link}
      />

      {showInProgressModal && <InProgressModal onClose={() => setShowInProgressModal(false)} />}

      <Outlet />

      {cookiesSlice.set === false && <CookieConsent onAcceptAll={() => dispatch(setAllAccepted())} />}
      {cookiesSlice.showSettings && (
        <CookieSettings
          onAcceptAll={() => dispatch(setAllAccepted())}
          onClose={() => dispatch(setShowSettings(false))}
          onSaveSettings={() => {
            dispatch(setSet())
            dispatch(setShowSettings(false))
          }}
        />
      )}

      <Footer onCookiesSettingsClick={() => dispatch(setShowSettings(true))} />
    </div>
  )
}
