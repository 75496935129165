import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { isLoggedIn } from "@fastdo-react"

export default function IsLoggedIn() {
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/404")
    }
  }, [navigate])

  return <></>
}
