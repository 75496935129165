import styles from "./ScatterHighlightModal.module.css"
import { Button, CenterModal, Spacer } from "@fastdo-react"
import agent from "common/api/agent"
import { useState } from "react"
import { toast } from "react-toastify"
import { MutualFunds_Get_GetMutualFundsItemResponse } from "common/api/exportedModels"

export interface Props {
  mutualFund: MutualFunds_Get_GetMutualFundsItemResponse
  onClose: (performed: boolean) => void
}

export default function DeleteModal({ mutualFund, onClose }: Props) {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const submit = async () => {
    if (!mutualFund.id) return
    setLoadingSubmit(true)
    try {
      await agent.MutualFunds.deleteSingle(mutualFund.id)
      toast.success("Odstraněno")
      onClose(true)
    } catch {}
    setLoadingSubmit(false)
  }

  return (
    <CenterModal show onShaderClick={() => onClose(false)}>
      <h3>{mutualFund.name}</h3>
      <Spacer />
      
      <p>Opravdu chcete odstranit fond?</p>
      <Spacer />

      <div className={styles.buttonsDiv}>
        <Button label="Odstranit" onClick={submit} loading={loadingSubmit} />
        <Spacer horizontal size={20} />
        <Button label="Zrušit" outlined={true} onClick={() => onClose(false)} />
      </div>
    </CenterModal>
  )
}
