import Table from "components/mutalFunds/table/Table"
import { HomeLayout } from "@fastdo-react"
import MutualFundsAdminCard from "components/pages/admin/MutualFundsAdminCard"

export default function MutualFundsPage() {
  return (
    <HomeLayout title="Podílové fondy">
      <MutualFundsAdminCard />

      <h1>Tabulka podílových fondů</h1>
      <Table isAdmin />
    </HomeLayout>
  )
}
