import agent from "common/api/agent"
import { ChangePasswordAdminPage as FastDoChangePasswordAdminPage } from "@fastdo-react"
import IsLoggedIn from "components/general/IsLoggedIn"

export default function ChangePasswordAdminPage() {
  const changePassword = async (password: string, newPassword: string, newPasswordConfirmation: string) => {
    try {
      await agent.SuperAdminUsers.changePassword({
        password: password,
        newPassword: newPassword,
        newPasswordConfirmation: newPasswordConfirmation,
      })
    } catch (error) {}
  }

  return (
    <>
      <IsLoggedIn />

      <FastDoChangePasswordAdminPage
        onSubmit={(password, newPassword, newPasswordConfirmation) =>
          changePassword(password, newPassword, newPasswordConfirmation)
        }
      />
    </>
  )
}
