import { useEffect } from "react"
import Head from "react-helmet"
import { HomeLayout } from "@fastdo-react"

export default function PersonalDataProtectionPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HomeLayout
      headProps={{
        HeadHelement: Head,
        title: "Ochrana osobních údajů - Srovnej investice",
        link: "https://srovnejinvestice.cz/personal-data-protection",
        imageCard: "https://srovnejinvestice.cz/assets/logos/logo-text.png",
      }}
      title="Zpracování a ochrana osobních údajů v Kompas Investment s.r.o."
    >
      <h2>Informace ke zpracování osobních dat</h2>
      <p>
        Kompas Investment s.r.o. se sídlem Purkyňova 920, 460 01 Liberec, IČ 01473476 dbá na ochranu osobních údajů,
        které nám předáváte. Je důležité, abyste věděli, že vaše osobní údaje zpracováváme zodpovědně, transparentně a
        máte nárok požádat o informace o evidovaných osobních údajích, jejich opravu, či výmaz, pokud jsou uděleny na
        základě Vámi uděleného souhlasu.
      </p>
      <p>
        V případě jakýchkoli dotazů týkajících se zpracování Vašich osobních údajů nás můžete kontaktovat na mailu
        info@srovnejinvestice.cz.
      </p>

      <h2>Zabezpečení Vašich osobních dat</h2>
      <p>
        Dbáme na bezpečnost osobních dat, které nám svěřujete. Přijali jsme vhodná technická a organizační opatření,
        abychom Vaše data dostatečně chránili. K Vašim osobním údajům nemá přístup žádná neoprávněná osoba a nepředáváme
        je bez Vašeho souhlasu dalším subjektům pro další zpracování, pokud to nevyžaduje zákon, nebo v případě ochrany
        našich právních zájmů.
      </p>

      <h2>Soulad s GDPR</h2>
      <p>
        Prohlašujeme, že jako správce vašich osobních údajů splňujeme veškeré zákonné povinnosti vyžadované platnou
        legislativou, zejména zákonem o ochraně osobních údajů a GDPR (Nařízení Evropského parlamentu a Rady EU 2016/679
        ze dne 27. dubna 2016), a tedy že: zpracováváme vaše osobní údaje jen na základě platného právního důvodu, a to
        především oprávněného zájmu, plnění smlouvy, zákonné povinnosti či uděleného souhlasu, plníme informační
        povinnost ještě před zahájením zpracování osobních údajů, umožníme vám a budeme vás podporovat v uplatňování a
        plnění vašich práv podle zákona o ochraně osobních údajů a GDPR.
      </p>

      <h2>Rozsah osobních údajů a účely zpracování</h2>
      <p>Zpracováváme osobní údaje, které nám svěříte sami, a to z následujících důvodů (pro naplnění těchto účelů):</p>

      <ul>
        <li>
          <strong>Zasílání informačních emailů v rámci informovanosti o nových službách</strong>
          <br /> Vaše osobní údaje v rozsahu: e-mail, který použijeme pro informování o nových službách Vaše osobní
          údaje si ponecháváme po dobu běhu promlčecích lhůt, pokud zákon nestanoví delší dobu k jejich uchování nebo
          jsme v konkrétních případech neuvedli jinak.
        </li>
      </ul>

      <h2>Soubory cookie</h2>
      <p>
        Když navštívíte naše webové stránky, jste informováni, že využíváme technologie ke shromažďování a ukládání
        informací pomocí souborů cookie do Vašeho zařízení. Soubory cookie jsou malé textové soubory, které nikam
        neodesíláme, ani je nijak nezpracováváme. Používáme cookies nezbytné pro správnou funkci webové stránky a při
        procházení našich webových stránek se zaznamenává vaše IP adresa, jak dlouho se na stránce zdržíte a ze které
        stránky přicházíte. Používání cookies pro měření návštěvnosti webu a přizpůsobení zobrazení webových stránek
        vnímáme jako oprávněný zájem správce, neboť díky tomu vám můžeme nabídnout ještě lepší služby. Naše webové
        stránky lze procházet také v režimu, který neumožňuje sbírání osobních údajů. Používání cookies, vyjma nutných
        cookies pro zajištění správné funkce webových stránek, můžete na svém počítači zakázat.
      </p>

      <h2>Vaše práva v souvislosti s ochranou osobních údajů</h2>

      <p>V souvislosti s ochranou osobních údajů máte řadu práv.</p>

      <p>
        Máte <strong>právo na informace,</strong> které je plněno již touto informační stránkou se zásadami zpracování
        osobních údajů.
      </p>

      <p>
        Díky <strong>právu na přístup</strong> nás můžete kdykoli vyzvat a ve lhůtě 30 dní vám doložíme, jaké vaše
        osobní údaje zpracováváme a proč. Naším právem pak je, že můžeme zamítnout požadavky na informace, které jsou
        bezdůvodné, či se nepřiměřeně opakují, případně jejich získání vyžaduje nepřiměřené úsilí, nebo by byly obtížně
        získatelné (typicky ze záložních systémů, archiválií apod.). Pokud se u vás něco změní nebo shledáte své osobní
        údaje neaktuální nebo neúplné, máte právo na doplnění a změnu osobních údajů.
      </p>
      <p>
        <strong>Právo na omezení zpracování</strong> můžete využít, pokud se domníváte, že zpracováváme vaše nepřesné
        údaje, domníváte se, že provádíme zpracování nezákonně, ale nechcete všechny údaje smazat nebo pokud jste
        vznesli námitku proti zpracování.
      </p>
      <p>
        <strong>Právo na výmaz (být zapomenut)</strong>
        <br />
        Vaším dalším právem je právo na výmaz (být zapomenut). Nechceme na vás zapomenout, ale pokud si to budete přát,
        máte na to právo. V takovém případě vymažeme veškeré vaše osobní údaje ze svého systému i ze systému všech
        dílčích zpracovatelů a záloh. Na zajištění práva na výmaz potřebujeme 30 dní.
      </p>
      <p>
        <strong>Stížnost u Úřadu na ochranu osobních údajů</strong>
        <br />
        Pokud máte pocit, že s vašimi údaji nezacházíme v souladu se zákonem, máte právo se se svou stížností kdykoli
        obrátit na Úřad pro ochranu osobních údajů. Budeme moc rádi pokud nejprve budete o tomto podezření informovat
        nás, abychom s tím mohli něco udělat a případné pochybení napravit.
      </p>

      <h2>Mlčenlivost</h2>
      <p>
        Dovolujeme si Vás ujistit, že naši zaměstnanci i spolupracovníci, kteří budou zpracovávat Vaše osobní údaje,
        jsou povinni zachovávat mlčenlivost o osobních údajích a o bezpečnostních opatřeních, jejichž zveřejnění by
        ohrozilo zabezpečení vašich osobních údajů. Tato mlčenlivost přitom trvá i po skončení závazkových vztahů s
        námi. Bez vašeho souhlasu nebudou vaše osobní údaje vydány žádné jiné třetí straně
      </p>

      <p>Tyto zásady zpracování osobních údajů platí od 28.9.2022</p>
    </HomeLayout>
  )
}
