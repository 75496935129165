import { NotFoundView as FastDoNotFoundView } from "@fastdo-react"
import { Link } from "react-router-dom"

export default function NotFoundView() {
  return (
    <FastDoNotFoundView
      header="Stránka nenalezena"
      text="Stránka, kterou hledáte, mohla být odstraněna, pokud se změnil její název, nebo je dočasně nedostupná."
      actionComponent={
        <Link to="/" className="fastdo-button fastdo-button-default">
          Zpět na web
        </Link>
      }
    />
  )
}
