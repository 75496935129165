import { HomeLayout } from "@fastdo-react"
import Table from "components/scatterHighlights/Table"

export default function ScatterHighlightsPage() {
  return (
    <HomeLayout title="Zvýraznění">
      <Table />
    </HomeLayout>
  )
}
