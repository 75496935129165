import styles from "./HomeTiles.module.css"
import HomeTile from "./HomeTile"
import ButtonWithInProgressModal from "components/general/ButtonWithInProgressModal"
import { Link } from "react-router-dom"
import { MutualFunds_Get_GetMutualFundsItemResponse } from "common/api/exportedModels"

export interface Props {
  mutualFundsCount: number
  selectedMutualFund: MutualFunds_Get_GetMutualFundsItemResponse | undefined
}

export default function HomeTiles({ mutualFundsCount, selectedMutualFund }: Props) {
  return (
    <>
      <div className={styles.sectionContainer}>
        <p>
          <strong>
            Proč doporučujeme investovat do portfolií KOMPAS? Protože jsou sestavena z podílových fondů, které mají
            vysoký výnos při nízkém riziku ztráty. Na následujícím příkladu si vysvětlíme, jak postupujeme:
          </strong>
        </p>
        <h2 style={{ color: "var(--fastdo-primary-color)" }}>
          Ujasníme si, co je to riziko ztráty a jaký má vztah k výnosu
        </h2>
        <p>
          Většina střadatelů se snaží předejít situaci mít na konci spoření méně, než kolik vložili. Zcela logicky
          nechtějí utrpět ztrátu. Proto potřebují vědět, jaké jim při spoření hrozí riziko ztráty. Uvědomují si, že jim
          ztráta hrozí především v situaci, pokud by svoje spoření museli ukončit v nepříznivé ekonomické situaci,
          typicky při výrazném poklesu kapitálových trhů. Následující graf ukazuje historický vývoj náhodně vybraného
          modrého dynamického a červeného konzervativního podílového fondu:
        </p>
        <img
          src="/assets/images/home/compare_chart.webp"
          alt="Rizikovost podílových fondů"
          title="Rizikovost podílových fondů"
          loading="eager"
          style={{ maxWidth: "unset", width: "100%" }}
        />
        <p>
          Vidíme, že hodnoty modrého dynamického fondu jsou prakticky po celou dobu vyšší než červeného konzervativního.
          To znamená, že by při ukončení investice do dynamického fondu v jakémkoliv okamžiku byla její hodnota vyšší
          než hodnota investice do fondu konzervativního, kromě krátkého období na začátku investice.
        </p>
        <p>
          Dále vidíme, že při výrazných poklesech v pravé polovině grafu (označeno šipkami) byl červený konzervativní na
          rozdíl od modrého dynamického v minusu, a tedy v případě ukončení investice by střadatel utrpěl ztrátu. Modrý
          dynamický byl v těchto poklesech naopak ziskový, a proto vykazuje nižší riziko ztráty než červený
          konzervativní.
        </p>
        <p style={{ textAlign: "center" }}>
          <strong>
            Tento příklad má tedy překvapivý výsledek, neboť dynamický fond má nejen vyšší výnos, ale je i méně rizikový
            než konzervativní.
          </strong>
        </p>
        <p>
          Ale říká se, že dynamické fondy jsou přece rizikovější než konzervativní, což dle současné metodiky dokládá
          vyšší hodnota SRRI u modrého dynamického oproti červenému konzervativnímu! Není to tedy jen vybraná,
          zavádějící ukázka?
        </p>
      </div>
      <div style={{ backgroundColor: "var(--fastdo-secondary-color)" }}>
        <div className={styles.sectionContainer}>
          <h2 style={{ color: "var(--fastdo-primary-color-light)" }}>
            Trychtýřovou metodou zjistíme, jak se výnos a riziko ztráty mění v čase
          </h2>
          <p style={{ color: "var(--fastdo-light-color)" }}>
            To zjistíme tak, že se podíváme na výsledky spoření mezi jakýmikoliv dvěma dny za historii obou fondů.
            Výsledky ukazuje následující trychtýřový graf, červený trychtýř patří konzervativnímu fondu, modrý
            dynamickému:
          </p>
          <img
            src="/assets/images/home/funnel_chart.webp"
            alt="Trychtýřová metoda"
            title="Trychtýřová metoda"
            loading="eager"
            style={{ maxWidth: "unset", width: "100%" }}
          />
          <p style={{ color: "var(--fastdo-light-color)" }}>
            Všechny výnosy v ročním vyjádření se pro konkrétní délky spoření nacházejí mezi spodní plnou a horní
            tečkovanou křivkou. Spodní spojuje nejnižší výnosy za celou historii fondu, tedy výnosy dosažené při
            nejhorších ekonomických scénářích. Horní pak spojuje nejvyšší výnosy za celou historii fondu. Prostřední
            čárkovaná spojuje průměrné výnosy.
          </p>
          <p style={{ color: "var(--fastdo-light-color)", marginBottom: "0" }}>Z grafu můžeme vyčíst:</p>
          <ul style={{ color: "var(--fastdo-light-color)" }}>
            <li>
              Fond, který má spodní plnou křivku položenou výš, je méně rizikový, protože v nejhorších ekonomických
              scénářích dosahoval lepších výsledků.
            </li>
            <li>
              Spodní křivky se s prodlužováním doby spoření přibližují k prostředním křivkám. S rostoucí délkou spoření
              se tedy snižuje riziko ztráty.
            </li>
            <li>
              Fond, který má prostřední čárkovanou křivku položenou výš, dosahuje lepších výsledků při standardních
              vývojích ekonomiky, které se vyskytují nejčastěji.
            </li>
            <li>
              Prostřední křivky vykazují obvykle vysokou stabilitu, průměrné výnosy se pro jednotlivé délky spoření
              příliš nemění.
            </li>
          </ul>
          <p style={{ color: "var(--fastdo-light-color)", textAlign: "center" }}>
            <strong>
              Trychtýřový graf nám tedy potvrdil, že modrý dynamický fond má vyšší výnos a nižší riziko ztráty než
              červený konzervativní a to platí pro všechny délky spoření delší než 1,5 roku.
            </strong>
          </p>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <h2>Jak si správně vybrat kvalitní podílový fond?</h2>
        <p>
          Především potřebujeme vědět, jak bude spoření dlouho trvat. Pro zvolenou dobu spoření pak potřebujeme znát
          průměrný výnos a riziko ztráty porovnávaných fondů. Tyto údaje odečteme z trychtýřových grafů:
        </p>
        <img
          src="/assets/images/home/funnel_chart_labeled.webp"
          alt="Trychtýřová metoda"
          title="Trychtýřová metoda"
          loading="eager"
          style={{ maxWidth: "unset", width: "100%" }}
        />
        <p style={{ marginBottom: "0" }}>Nyní už můžeme vybírat:</p>
        <ul>
          <li>
            Pokud budeme preferovat co nejnižší riziko ztráty, zvolíme pro spoření na 1 rok červený (-19,2% je lepší než
            -24%) a při spoření na 6 let modrý (+9,5% je lepší než +4,7%).
          </li>
          <li>
            Pokud budeme dávat přednost výši průměrného výnosu, zvolíme v obou případech modrý (+13,3% je lepší než
            +5,2% a +13,7% je lepší než +6,4%).
          </li>
          <li>
            Ale můžeme také chtít co nejmenší riziko ztráty, ale při co nejvyšším průměrném výnosu. V tomto případě je
            nejlepším kritériem kvalita fondu, kterou spočítáme jako součet průměrného výnosu a rizika ztráty. Červený
            má pro spoření na 1 rok kvalitu +5,2% + (-19,2%) = -14% a modrý +13,3% + (-24%) = -10,7%, pro 6 let má
            červený kvalitu +6,4% + 4,7% = +11,1% a modrý +13,7% + 9,5% = +23,2%. Kvalita modrého je v obou případech
            vyšší, a vybereme ho tedy pro naši investici při spoření na 1 rok i 6 let.
          </li>
        </ul>
        <p>
          <strong>Tímto způsobem jsme vybrali nejkvalitnější podílové fondy do portfolií KOMPAS.</strong>
        </p>
        <p>Přejděte do srovnávače a porovnejte kvalitu svého fondu s jinými z naší nabídky</p>
        <Link
          to={`/analysis?isina=${selectedMutualFund?.isin ?? "GSPC"}&isinb=KOMPAS03`}
          className="fastdo-button fastdo-button-default"
        >
          Přejít do srovnávače
        </Link>
      </div>
      <HomeTile
        title="Máme unikátní know-how"
        imgSrc="/assets/images/home/zarovka.webp"
        text="Našli jsme odpověď na otázku, jak porovnat kvalitu podílových fondů. Zjistili jsme, že rozdíly v jejich kvalitě
          jsou dlouhodobě stabilní, a především, že ty kvalitnější poskytují vyšší výnos při nižším riziku ztráty. Tato nově
          objevená vlastnost otevírá zcela nový přístup k investování, který investorům umožňuje naspořit si výrazně více
          prostředků při nižším riziku ztráty než s většinou obvykle nabízených spořících a investičních programů."
      />
      <HomeTile
        imageRight
        title="Inspirujeme se nejlepšími"
        imgSrc="/assets/images/home/mozek.webp"
        text="Za podílovými fondy stojí týmy odborníků. Jako v každém oboru i zde jsou odborníci horší, průměrní a nejlepší. O
          jejich kvalitě mluví výsledky jejich práce. Za jednoho z nejúspěšnějších investičních správců na světě je
          považován Warren Buffet a jeho tým. Dokáží najít kvalitní akcie, nakoupí je a dlouhodobě drží. A investoři věří,
          že když to dobře uměli v minulosti, budou to dobře umět i v budoucnu. My to děláme stejně. Portfolia KOMPAS
          sestavujeme z nejkvalitnějších podílových fondů a dlouhodobě je držíme."
      >
        <p>
          <i>„At the end of the day, you bet on people, not on strategies.“</i>
        </p>
        <p style={{ textAlign: "right" }}>
          <i>Lawrence Bossidy</i>
        </p>
      </HomeTile>
      <HomeTile title="Transparentnost" imgSrc="/assets/images/home/lupa.webp">
        <p style={{ color: "var(--fastdo-light-color)" }}>
          Náš srovnávač poskytuje možnost porovnávat mezi sebou {mutualFundsCount} podílových fondů. Najdete zde i
          kalkulačku, která vám ukáže vývoj hodnoty investice při průměrných, nejlepších a nejhorších ekonomických
          scénářích. Součástí našeho webu je i sada článků, které všechny aspekty našeho nového přístupu k investování
          vysvětlují. Každému tím dáváme možnost ověřit si, jak a proč podílové fondy hodnotíme a porovnáváme. Máte-li
          otázky, zeptejte se nás na
        </p>
        <a className="fastdo-button fastdo-button-default" href="mailto:info@srovnejinvestice.cz">
          info@srovnejinvestice.cz
        </a>
      </HomeTile>
      <HomeTile
        imageRight
        title="Objektivita díky technologiím"
        imgSrc="/assets/images/home/cip.webp"
        text="Výpočet kvality, její aktualizace a rozšiřování nabídky podílových fondů je zajištěno vlastním unikátním
          automatizovaným systémem. Hodnocení kvality je tedy jednoznačné, objektivní a stále aktuální."
      />
      <HomeTile
        title="Spolehlivost, stabilita, integrita"
        imgSrc="/assets/images/home/optisk.webp"
        text="Výběrem nejkvalitnějších podílových fondů, které jsou v portfoliích KOMPAS, investujete do nejlepších
          investičních správců. To vám dává velkou šanci, že kvalita jejich fondů bude nadprůměrná i v budoucnu. Pro
          zajištění stabilně vysoké kvality jsou portfolia KOMPAS vhodně diverzifikována do několika perspektivních
          segmentů a v rámci segmentu do dvou až tří nejlepších podílových fondů. Dlouhodobě jim věříme, sami do nich
          investujeme. Investovat do portfolií KOMPAS je jednoduché, vše lze vyřešit jednoduše a bezpečně online."
      >
        <ButtonWithInProgressModal label="Chci investovat do portfolií KOMPAS" />
      </HomeTile>
    </>
  )
}
