import { useParams } from "react-router-dom"
import { HomeLayout } from "@fastdo-react"
import EditArticleCard from "components/pages/articles/EditArticleCard"

export default function EditArticlePage() {
  const { nameUrl } = useParams()

  return (
    <HomeLayout title="Upravit článek">
      <>{nameUrl && <EditArticleCard nameUrl={nameUrl} />}</>
    </HomeLayout>
  )
}
