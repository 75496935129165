import { Spacer, toCzCurrencyString } from "@fastdo-react"
import styles from "./InvestmentModelationChartCustomTooltip.module.css"

export default function InvestmentModelationChartCustomTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    var aMax = payload[0].value[1]
    var bMax = payload[1].value[1]
    var aMed = payload[2].value[1]
    var bMed = payload[3].value[1]
    var aMin = payload[4].value[1]
    var bMin = payload[5].value[1]
    var input = payload[6].value[1]

    return (
      <div className={styles.component}>
        <p className={styles.labelP}>Rok: {label}</p>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.inputLabelP}>Vklad: </p>
          <p className={styles.inputLabelValueP}>{toCzCurrencyString(input)}</p>
        </div>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>A optim: </p>
          <p className={styles.aLabelValueP}>{toCzCurrencyString(aMax)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>B optim: </p>
          <p className={styles.bLabelValueP}>{toCzCurrencyString(bMax)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.diffLabelP}>optim A-B: </p>
          <p className={styles.diffLabelValueP}>{toCzCurrencyString(parseFloat(aMax) - parseFloat(bMax))}</p>
        </div>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>A neut: </p>
          <p className={styles.aLabelValueP}>{toCzCurrencyString(aMed)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>B neut: </p>
          <p className={styles.bLabelValueP}>{toCzCurrencyString(bMed)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.diffLabelP}>neut A-B: </p>
          <p className={styles.diffLabelValueP}>{toCzCurrencyString(parseFloat(aMed) - parseFloat(bMed))}</p>
        </div>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>A pesim: </p>
          <p className={styles.aLabelValueP}>{toCzCurrencyString(aMin)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>B pesim: </p>
          <p className={styles.bLabelValueP}>{toCzCurrencyString(bMin)}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.diffLabelP}>pesim A-B: </p>
          <p className={styles.diffLabelValueP}>{toCzCurrencyString(parseFloat(aMin) - parseFloat(bMin))}</p>
        </div>
      </div>
    )
  }

  return null
}
