import { Fragment, useEffect, useState } from "react"
import agent from "../../../common/api/agent"
import { InvestingInterests_Get_GetResponse } from "../../../common/api/exportedModels"
import { AiFillDelete } from "react-icons/ai"
import { ActionRowCard, Button, ButtonWithAsk, CircularProgressPage, Spacer } from "@fastdo-react"
import { toast } from "react-toastify"

export default function InvestingInterestsEmails() {
  const [response, setResponse] = useState<InvestingInterests_Get_GetResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [loadingDeleteEmail, setLoadingDeleteEmail] = useState<string | undefined>(undefined)
  const [loadingGetCsv, setLoadingGetCsv] = useState(false)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      try {
        var response = await agent.InvestingInterests.get()
        setResponse(response)
      } catch {}
      setLoading(false)
    }
    get()
  }, [])

  const del = async (email: string) => {
    setLoadingDeleteEmail(email)
    try {
      await agent.InvestingInterests.deleteEmail({ email: email })
      toast.success("Email odstraněn")
      setResponse((prev) => {
        if (!prev || !prev.emails) return prev
        return {
          ...prev,
          emails: prev.emails.filter((item) => item !== email),
        }
      })
    } catch {}
    setLoadingDeleteEmail(undefined)
  }

  const getCsv = async () => {
    setLoadingGetCsv(true)
    try {
      await agent.InvestingInterests.getCsv()
    } catch {}
    setLoadingGetCsv(false)
  }

  const deleteAll = async () => {
    try {
      await agent.InvestingInterests.deleteAll()
      setResponse((prev) => {
        if (!prev || !prev.emails) return null
        return {
          ...prev,
          emails: [],
        }
      })
      toast.success("Úspěšně odstraněno")
    } catch {}
  }

  return (
    <>
      <Button label="Stáhnout csv" onClick={getCsv} loading={loadingGetCsv} />
      <Spacer />

      <ButtonWithAsk
        buttonProps={{ label: "Odstranit vše", danger: true }}
        actionWithAskProps={{ title: "Odstranit vše", onAccepted: deleteAll }}
      />
      <Spacer />

      {loading ? (
        <CircularProgressPage />
      ) : (
        <>
          {response?.emails!.map((item, index) => (
            <Fragment key={index}>
              <ActionRowCard
                id={item}
                label={item}
                actionItems={[
                  {
                    icon: AiFillDelete,
                    onClick: () => del(item),
                    loading: loadingDeleteEmail === item,
                  },
                ]}
              />
              <Spacer />
            </Fragment>
          ))}
        </>
      )}
    </>
  )
}
