import View from "./View"
import ArticleDetailPage from "./back/ArticleDetailPage"
import PersonalDataProtectionPage from "./back/PersonalDataProtectionPage"
import AboutPage from "./main/AboutPage"
import AnalysisPage from "./main/AnalysisPage"
import ArticlesPage from "./main/ArticlesPage"
import HomePage from "./main/HomePage"

export const routes = {
  path: "",
  element: <View />,
  children: [
    { path: "", element: <HomePage /> },
    { path: "articles", element: <ArticlesPage /> },
    { path: "articles/:nameUrl", element: <ArticleDetailPage /> },
    { path: "analysis", element: <AnalysisPage /> },
    { path: "about", element: <AboutPage /> },
    { path: "cookies", element: <PersonalDataProtectionPage /> },
  ],
}
