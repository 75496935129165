import styles from "./ScatterHighlightModal.module.css"
import { Button, CenterModal, Spacer, TextField } from "@fastdo-react"
import agent from "common/api/agent"
import { useState } from "react"
import { toast } from "react-toastify"
import {
  MutualFunds_Get_GetMutualFundsItemResponse,
  ScatterHighlights_Get_GetItemResponse,
} from "common/api/exportedModels"

export interface Props {
  mutualFund?: MutualFunds_Get_GetMutualFundsItemResponse
  editItem?: ScatterHighlights_Get_GetItemResponse | undefined
  onClose: (performed: boolean, editItem?: ScatterHighlights_Get_GetItemResponse) => void
}

export default function ScatterHighlightModal({ mutualFund = undefined, editItem = undefined, onClose }: Props) {
  const [color, setColor] = useState(editItem?.color ?? "#")
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const submit = async () => {
    setLoadingSubmit(true)

    if (editItem && editItem.id) {
      try {
        await agent.ScatterHighlights.edit(editItem.id, { color: color })
        toast.success("Upraveno")
        onClose(true, { ...editItem, color: color })
      } catch {}
    } else {
      try {
        await agent.ScatterHighlights.add({
          color: color,
          mutualFundId: mutualFund?.id,
        })
        toast.success("Fond zvýrazněn")
        onClose(true)
      } catch {}
    }

    setLoadingSubmit(false)
  }

  return (
    <CenterModal show onShaderClick={() => onClose(false)}>
      <h3>{editItem ? "Upravit zvýraznění" : "Zvýraznit fond"}</h3>
      <Spacer size={20} />

      <TextField label="Barva" value={color} onChange={(value) => setColor(value)} />
      <Spacer size={30} />

      <div className={styles.buttonsDiv}>
        <Button label={editItem ? "Upravit" : "Zvýraznit"} onClick={() => submit()} loading={loadingSubmit} />
        <Spacer horizontal size={20} />
        <Button label="Zrušit" outlined={true} onClick={() => onClose(false)} />
      </div>
    </CenterModal>
  )
}
