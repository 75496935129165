import { HomeLayout } from "@fastdo-react"
import CreateArticleCard from "components/pages/articles/CreateArticleCard"

export default function CreateArticlePage() {
  return (
    <HomeLayout title="Vytvořit článek">
      <CreateArticleCard />
    </HomeLayout>
  )
}
