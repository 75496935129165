export const wrapperStyle: React.CSSProperties = {
  opacity: "0.7",
  outline: "none",
}

export const labelStyle: React.CSSProperties = {
  fontSize: "1rem",
  fill: "#666666",
  color: "#666666",
  textAlign: "center",
}
