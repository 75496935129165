import styles from "./InvestmentModelationCard.module.css"
import { useEffect, useState } from "react"
import { AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Area, Legend } from "recharts"
import InvestmentModelationChartCustomTooltip from "./InvestmentModelationChartCustomTooltip"
import {
  Card,
  CurrencyTextField,
  PrimaryCircularProgress,
  PrimarySlider,
  Spacer,
  toCzCurrencyString,
} from "@fastdo-react"
import { labelStyle } from "components/mutalFunds/plots/common"
import { MutualFunds_GetDetail_GetDetailResponse } from "common/api/exportedModels"

export interface Props {
  mutualFundDetailA: MutualFunds_GetDetail_GetDetailResponse
  mutualFundDetailB: MutualFunds_GetDetail_GetDetailResponse
}

export default function InvestmentModelationCard({ mutualFundDetailA, mutualFundDetailB }: Props) {
  const [data, setData] = useState<any[] | null>(null)
  const [jedn, setJedn] = useState(100000)
  const [prav, setPrav] = useState(3000)
  const [investmentLength, setInvestmentLength] = useState(15)
  const [investmentLengthDisplay, setInvestmentLengthDisplay] = useState(15)

  const zX = (x: number, a: number, b: number, c: number) => a * Math.pow(x, b) + c
  const mX = (x: number, a: number, b: number) => a * x + b
  const yX = (inv: number, z: number, x: number) => inv * Math.pow(1 + z / 100, x)

  useEffect(() => {
    var coeffA = mutualFundDetailA?.developmentModelingCoefficients
    var coeffB = mutualFundDetailB?.developmentModelingCoefficients

    var yMaxJednArrayA = []
    var yMedJednArrayA = []
    var yMinJednArrayA = []
    var yMaxJednArrayB = []
    var yMedJednArrayB = []
    var yMinJednArrayB = []
    for (var i = 1; i <= investmentLength; i++) {
      yMaxJednArrayA.push(yX(jedn, zX(i, coeffA?.aMaxJedn!, coeffA?.bMaxJedn!, coeffA?.cMaxJedn!), i))
      yMedJednArrayA.push(yX(jedn, mX(i, coeffA?.aMedJedn!, coeffA?.bMedJedn!), i))
      yMinJednArrayA.push(yX(jedn, zX(i, coeffA?.aMinJedn!, coeffA?.bMinJedn!, coeffA?.cMinJedn!), i))
      yMaxJednArrayB.push(yX(jedn, zX(i, coeffB?.aMaxJedn!, coeffB?.bMaxJedn!, coeffB?.cMaxJedn!), i))
      yMedJednArrayB.push(yX(jedn, mX(i, coeffB?.aMedJedn!, coeffB?.bMedJedn!), i))
      yMinJednArrayB.push(yX(jedn, zX(i, coeffB?.aMinJedn!, coeffB?.bMinJedn!, coeffB?.cMinJedn!), i))
    }

    var yMaxPravASum = 0
    var yMedPravASum = 0
    var yMinPravASum = 0
    var yMaxPravBSum = 0
    var yMedPravBSum = 0
    var yMinPravBSum = 0

    var yMaxPravArrayA = []
    var yMedPravArrayA = []
    var yMinPravArrayA = []
    var yMaxPravArrayB = []
    var yMedPravArrayB = []
    var yMinPravArrayB = []

    for (var m = 1; m <= investmentLength * 12; m++) {
      var yearFactor = 1 + m * (1 / 12)

      yMaxPravASum += yX(prav, zX(yearFactor, coeffA?.aMaxPrav!, coeffA?.bMaxPrav!, coeffA?.cMaxPrav!), yearFactor)
      yMedPravASum += yX(prav, mX(yearFactor, coeffA?.aMedPrav!, coeffA?.bMedPrav!), yearFactor)
      yMinPravASum += yX(prav, zX(yearFactor, coeffA?.aMinPrav!, coeffA?.bMinPrav!, coeffA?.cMinPrav!), yearFactor)
      yMaxPravBSum += yX(prav, zX(yearFactor, coeffB?.aMaxPrav!, coeffB?.bMaxPrav!, coeffB?.cMaxPrav!), yearFactor)
      yMedPravBSum += yX(prav, mX(yearFactor, coeffB?.aMedPrav!, coeffB?.bMedPrav!), yearFactor)
      yMinPravBSum += yX(prav, zX(yearFactor, coeffB?.aMinPrav!, coeffB?.bMinPrav!, coeffB?.cMinPrav!), yearFactor)

      if (m % 12 === 0) {
        yMaxPravArrayA.push(yMaxPravASum)
        yMedPravArrayA.push(yMedPravASum)
        yMinPravArrayA.push(yMinPravASum)
        yMaxPravArrayB.push(yMaxPravBSum)
        yMedPravArrayB.push(yMedPravBSum)
        yMinPravArrayB.push(yMinPravBSum)
      }
    }

    var inputs = []
    for (var y = 1; y <= investmentLength; y++) {
      inputs.push(jedn + y * 12 * prav)
    }

    var dataItems = []
    for (i = 0; i < yMaxJednArrayA.length; i++) {
      var item = {
        name: i + 1,
        yMaxA: Math.round(yMaxJednArrayA[i] + yMaxPravArrayA[i]),
        yMedA: Math.round(yMedJednArrayA[i] + yMedPravArrayA[i]),
        yMinA: Math.round(yMinJednArrayA[i] + yMinPravArrayA[i]),
        yMaxB: Math.round(yMaxJednArrayB[i] + yMaxPravArrayB[i]),
        yMedB: Math.round(yMedJednArrayB[i] + yMedPravArrayB[i]),
        yMinB: Math.round(yMinJednArrayB[i] + yMinPravArrayB[i]),
        input: inputs[i],
      }

      dataItems.push({
        name: i + 1,
        yMaxA: [item.yMinA, item.yMaxA],
        yMaxB: [item.yMinB, item.yMaxB],
        yMedA: [item.yMedA, item.yMedA],
        yMedB: [item.yMedB, item.yMedB],
        yMinA: [item.yMinA, item.yMinA],
        yMinB: [item.yMinB, item.yMinB],
        input: [item.input, item.input],
      })
    }

    setData(dataItems)
  }, [mutualFundDetailA, mutualFundDetailB, jedn, prav, investmentLength])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
  })

  const handleResize = () => {
    if (window.innerWidth < 950) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const [showTooltip, setShowTooltip] = useState(true)

  const onScroll = () => {
    setShowTooltip(false)
  }

  useEffect(() => {
    if (isMobile) {
      window.removeEventListener("scroll", onScroll)
      window.addEventListener("scroll", onScroll, { passive: true })
      return () => window.removeEventListener("scroll", onScroll)
    }
  }, [isMobile])

  return (
    <div className={styles.component}>
      <Card>
        <div className={styles.textContainer}>
          <h2>Porovnání budoucího vývoje investic</h2>

          <p>
            Modelujeme optimistický, neutrání a pesimistický vývoj na základě toho, jak se ceny vybraných fondů vyvíjely
            vůči sobě dodnes.
          </p>
        </div>
        <Spacer size={20} />

        <div className={styles.componentContent}>
          <div className={styles.settingsContainer}>
            <CurrencyTextField value={jedn} onChange={(value) => setJedn(value)} label="Jednorázový vklad" />
            <Spacer size={20} />

            <CurrencyTextField value={prav} onChange={(value) => setPrav(value)} label="Měsíční vklad" />
            <Spacer size={20} />

            <p className={styles.settingsLabelP}>Investiční horizont</p>
            <div className={styles.sliderDiv}>
              <PrimarySlider
                min={3}
                max={40}
                marks={[
                  { value: 3, label: "3" },
                  { value: 40, label: "40" },
                ]}
                value={investmentLengthDisplay}
                onChange={(value) => setInvestmentLengthDisplay(value as number)}
                onChangeCommitted={(value) => setInvestmentLength(value as number)}
              />
            </div>
            <div className={styles.investmentLengthLabelDiv}>
              <p>
                <strong>{investmentLength} let</strong>
              </p>
            </div>
            <Spacer size={5} />
          </div>
          <div className={styles.graphContainer}>
            <div className={styles.graphLegendContainer}>
              <div style={{ marginLeft: isMobile ? "" : "170px" }}>
                <div className={styles.graphLegendItemDiv}>
                  <hr style={{ borderTop: "3px dotted var(--fastdo-secondary-color)" }} />
                  <p>Optimistický scénář</p>
                </div>
                <div className={styles.graphLegendItemDiv}>
                  <hr style={{ borderTop: "3px dashed var(--fastdo-secondary-color)" }} />
                  <p>Neutrální scénář</p>
                </div>
                <div className={styles.graphLegendItemDiv}>
                  <hr style={{ borderTop: "3px solid var(--fastdo-secondary-color)" }} />
                  <p>Pesimistický scénář</p>
                </div>
                <div className={styles.graphLegendItemDiv}>
                  <hr style={{ borderTop: "3px solid var(--fastdo-primary-color)" }} />
                  <p>Vklad</p>
                </div>
              </div>
            </div>
            {data ? (
              <div onClick={() => setShowTooltip(true)}>
                <ResponsiveContainer width="100%" height={500}>
                  <AreaChart data={data!}>
                    <defs>
                      <linearGradient id="colorA" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#e76f51" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#e76f51" stopOpacity={0.3} />
                      </linearGradient>
                      <linearGradient id="colorB" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#264653" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#264653" stopOpacity={0.3} />
                      </linearGradient>
                    </defs>
                    <Legend iconType="plainline" verticalAlign="top" wrapperStyle={{ visibility: "hidden" }} />
                    <YAxis
                      tickFormatter={(label: any) => toCzCurrencyString(label)}
                      width={isMobile ? 90 : 170}
                      style={{ fontSize: isMobile ? "0.9rem" : "" }}
                    />
                    <XAxis
                      dataKey="name"
                      angle={0}
                      dx={0}
                      dy={20}
                      height={55}
                      style={{ fontSize: isMobile ? "0.9rem" : "" }}
                    />
                    <Tooltip
                      content={<InvestmentModelationChartCustomTooltip />}
                      wrapperStyle={{
                        textAlign: "start",
                        opacity: "0.7",
                        display: showTooltip ? "" : "none",
                        outline: "none",
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="yMaxA"
                      name="A optim"
                      stroke="#e76f51"
                      strokeWidth={2.5}
                      dot={false}
                      strokeDasharray="3 3"
                      fill="url(#colorA)"
                    />
                    <Area
                      type="monotone"
                      dataKey="yMaxB"
                      name="B optim"
                      stroke="#264653"
                      strokeWidth={2.5}
                      dot={false}
                      strokeDasharray="3 3"
                      fill="url(#colorB)"
                    />
                    <Area
                      type="monotone"
                      dataKey="yMedA"
                      name="A neut"
                      stroke="#e76f51"
                      strokeWidth={2.5}
                      strokeDasharray="12 3"
                      dot={false}
                    />
                    <Area
                      type="monotone"
                      dataKey="yMedB"
                      name="B neut"
                      stroke="#264653"
                      strokeWidth={2.5}
                      strokeDasharray="12 3"
                      dot={false}
                    />
                    <Area
                      type="monotone"
                      dataKey="yMinA"
                      name="A pesim"
                      stroke="#e76f51"
                      strokeWidth={2.5}
                      dot={false}
                    />
                    <Area
                      type="monotone"
                      dataKey="yMinB"
                      name="B pesim"
                      stroke="#264653"
                      strokeWidth={2.5}
                      dot={false}
                    />
                    <Area type="monotone" dataKey="input" name="Input" stroke="#ae8600" strokeWidth={2.5} dot={false} />
                  </AreaChart>
                </ResponsiveContainer>
                <p
                  style={{
                    ...labelStyle,
                    marginLeft: isMobile ? "90px" : "170px",
                  }}
                >
                  Investiční horizont [let]
                </p>
              </div>
            ) : (
              <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PrimaryCircularProgress size={80} />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}
