import { HomeLayout } from "@fastdo-react"
import InvestingInterestsEmails from "components/pages/admin/InvestingInterestsEmails"

export default function HomePage() {
  return (
    <HomeLayout title="Admin">
      <h1>Zadané emaily (Chci investovat)</h1>
      <InvestingInterestsEmails />
    </HomeLayout>
  )
}
