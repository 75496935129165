import styles from "./HomeTile.module.css"

export interface Props {
  imageRight?: boolean
  imgSrc?: string
  title: string
  text?: string
  children?: JSX.Element[] | JSX.Element
}

export default function HomeTile({ imageRight = false, imgSrc = "", title, text = "", children = <></> }: Props) {
  const getComponentClass = () => {
    let className = styles.component
    if (imageRight) className += " " + styles.componentImageRight
    else className += " " + styles.componentImageLeft
    return className
  }

  return (
    <div className={getComponentClass()}>
      <div className={styles.innerDiv}>
        {imgSrc && <img src={imgSrc} alt={title} title={title} loading="eager" />}
        <div className={styles.textDiv}>
          <h2 className={styles.title}>{title}</h2>
          {text && <p className={styles.text}>{text}</p>}
          {children}
        </div>
      </div>
    </div>
  )
}
