import styles from "./MutualFundsDetailComparison.module.css"
import { MutualFunds_GetDetail_GetDetailResponse } from "../../common/api/exportedModels"
import MutualFundDetailCard from "./MutualFundDetailCard"

export interface Props {
  mutualFundA: MutualFunds_GetDetail_GetDetailResponse
  mutualFundB: MutualFunds_GetDetail_GetDetailResponse
}

export default function MutualFundsDetailComparison({ mutualFundA, mutualFundB }: Props) {
  return (
    <div className={styles.component}>
      <div className={[styles.halfContainer, styles.leftContainer].join(" ")}>
        <MutualFundDetailCard mutualFund={mutualFundA} />
      </div>
      <div className={[styles.halfContainer, styles.rightContainer].join(" ")}>
        <MutualFundDetailCard mutualFund={mutualFundB} />
      </div>
    </div>
  )
}
