import styles from "./ScatterPlot.module.css"
import agent from "common/api/agent"
import {
  MutualFunds_GetScatterData_GetScatterDataResponse,
  MutualFunds_Get_GetMutualFundsItemResponse,
} from "common/api/exportedModels"
import { FC, useEffect, useState } from "react"
import {
  CartesianGrid,
  RectangleProps,
  PolygonProps,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  Rectangle,
  Polygon,
  Dot,
  DotProps,
} from "recharts"
import { labelStyle, wrapperStyle } from "./common"
import RecommendationTextField from "../RecommendationTextField"
import { Button, PrimaryCircularProgress, Spacer } from "@fastdo-react"
import ButtonWithInProgressModal from "components/general/ButtonWithInProgressModal"

export interface Props {
  mutualFundsCount: number | undefined
  onWantKnowMoreClick: () => void
  selectedMutualFund: MutualFunds_Get_GetMutualFundsItemResponse | undefined
  onSelectedMutualFundChange: (mutualFund: MutualFunds_Get_GetMutualFundsItemResponse | undefined) => void
}

export default function ScatterPlot({
  mutualFundsCount,
  onWantKnowMoreClick,
  selectedMutualFund,
  onSelectedMutualFundChange,
}: Props) {
  const [response, setResponse] = useState<MutualFunds_GetScatterData_GetScatterDataResponse | null>(null)
  const [scatterData, setScatterData] = useState<any[]>([])
  const [highlightedScatterData, setHighlightedScatterData] = useState<any[]>([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      var response = await agent.MutualFunds.getScatterData()
      setResponse(response)

      if (response.items) {
        var highlightedIds = response?.scatterHighlights?.map((item) => item.mutualFundId)

        var parsedScatterData = response?.items
          ?.filter((item) => highlightedIds?.includes(item.id) === false)
          .map((item) => ({
            worstHistoricalYield: item.worstHistoricalYield,
            expectedYield: item.expectedYield,
          }))

        setScatterData(parsedScatterData)
      }

      if (response.scatterHighlights) {
        var parsedHighlightedScatterData = response?.scatterHighlights?.map((item) => {
          var mutalFund = response?.items?.find((mutalFundItem) => mutalFundItem.id === item.mutualFundId)
          return {
            item: {
              worstHistoricalYield: mutalFund?.worstHistoricalYield,
              expectedYield: mutalFund?.expectedYield,
            },
            color: item.color,
          }
        })
        setHighlightedScatterData(parsedHighlightedScatterData)
      }

      setLoading(false)
    }
    get()
  }, [])

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const worstHistoricalYield = payload[0].payload.worstHistoricalYield
      const expectedYield = payload[0].payload.expectedYield

      var mutualFund = response?.items?.find(
        (item) => item.worstHistoricalYield === worstHistoricalYield && item.expectedYield === expectedYield,
      )

      const items = [
        { name: "Pořadí", value: mutualFund?.order },
        { name: "Kvalita", value: mutualFund?.quality?.toFixed(1).replace(".", ",") + " %" },
        { name: "Investiční společnost", value: mutualFund?.investmentCompany },
        { name: "Třída aktiv", value: mutualFund?.assetClass },
        { name: "Měna", value: mutualFund?.currency },
        { name: "Stáří fondu", value: mutualFund?.age?.toFixed(1).replace(".", ",") + " let" },
        { name: "Průměrný výnos", value: mutualFund?.expectedYield?.toFixed(1).replace(".", ",") + " %" },
      ]

      return (
        <div className={styles.customTooltip_Container}>
          <h3>{mutualFund?.name}</h3>
          <Spacer />
          {items.map((item, index) => (
            <div key={index} className={styles.customTooltip_itemContainer}>
              <p className={styles.customTooltip_itemTitle}>{item.name}</p>
              <p>
                <strong>{item.value}</strong>
              </p>
            </div>
          ))}
        </div>
      )
    }

    return null
  }

  const strokeWidth = 0.5
  const rhombusSize = 5.5

  const redColor = "#F84F31"
  const greenColor = "#00c154"

  const ShapeDefault: FC<PolygonProps> = ({ x, y }: PolygonProps) => (
    <Polygon
      points={[
        { x: (x as number) - rhombusSize, y: y as number },
        { x: x as number, y: (y as number) + rhombusSize },
        { x: (x as number) + rhombusSize, y: y as number },
        { x: x as number, y: (y as number) - rhombusSize },
      ]}
      x={x}
      y={y}
      fill="#007cb6ba"
      stroke="#000000"
      strokeWidth={strokeWidth}
      width={8}
      height={8}
    />
  )

  const ShapeSelected: FC<RectangleProps> = ({ x, y }: any) => (
    <Rectangle
      x={x - 10}
      y={y - 10}
      fill={redColor}
      stroke="#000000"
      strokeWidth={2 * strokeWidth}
      width={20}
      height={20}
    />
  )

  const ShapeHighlighted: FC<DotProps> = ({ cx, cy, fill, stroke, strokeWidth }) => {
    return <Dot cx={cx} cy={cy} r={8} fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
  }

  const handleSelect = (isin: string) => {
    var mutualFund = response?.items?.find((item) => item.isin === isin)
    if (mutualFund) onSelectedMutualFundChange(mutualFund)
  }

  return (
    <div>
      <div className={styles.descriptionDiv}>
        <p>
          Pro ověření kvality vašeho podílového fondu, ETF fondu nebo účastnického fondu doplňkového penzijního spoření
          zadejte do kolonky „Hledat fond“ jeho ISIN, nebo název. Tyto údaje najdete v ročním výpisu nebo v on-line
          webovém náhledu svého spoření. Zjistíte tak, jakého výnosu a rizika ztráty s ním dosáhnete při dlouhodobém
          investování ve srovnání s <strong>{mutualFundsCount}</strong> podílovými fondy dostupnými na českém trhu.
        </p>
      </div>

      <div className={styles.commandsDiv}>
        <RecommendationTextField onRecommendationClick={handleSelect} />
      </div>
      <Spacer size={16} />

      {selectedMutualFund && (
        <div className={styles.descriptionDiv}>
          <p>
            Váš fond: <strong>{selectedMutualFund.name}</strong>
          </p>
          <ul>
            <li>
              má délku historie <strong>{selectedMutualFund.age?.toFixed(1).replace(".", ",")} let</strong>,
            </li>
            <li>
              při dlouhodobém spoření můžete očekávat průměrný roční výnos{" "}
              <strong>{selectedMutualFund.expectedYield?.toFixed(1).replace(".", ",")} %</strong> a nejhorší roční výnos{" "}
              <strong>{selectedMutualFund.worstHistoricalYield?.toFixed(1).replace(".", ",")} %</strong>,
            </li>
            <li>
              má hodnotu kvality <strong>{selectedMutualFund.quality?.toFixed(1).replace(".", ",")} %</strong> jako
              součet průměrného a nejhoršího ročního výnosu,
            </li>
            <li>
              a díky tomu je na <strong>{selectedMutualFund.order}</strong> místě z <strong>{mutualFundsCount}</strong>{" "}
              fondů.
            </li>
          </ul>
          <p>
            V následující vizualizaci je váš fond zvýrazněn červeně a vidíte jeho postavení mezi ostatními fondy. Čím
            jsou fondy více vpravo nahoře, tím jsou kvalitnější, mají vyšší výnos při nižším riziku ztráty. Oranžové
            body jsou široce diverzifikovaná portfolia KOMPAS, která jsme sestavili z nejlepších fondů pro dlouhodobé
            spoření:
          </p>
        </div>
      )}

      <div className={styles.scatterDiv}>
        {loading ? (
          <PrimaryCircularProgress size={120} />
        ) : (
          <>
            <ResponsiveContainer width="100%" height={600}>
              <ScatterChart
                margin={{
                  top: 0,
                  right: 20,
                  bottom: 0,
                  left: 6,
                }}
              >
                <defs>
                  <linearGradient id="background" x1="0" y1="1" x2="1" y2="0">
                    <stop offset="30%" stopColor={redColor} stopOpacity={0.2} />
                    <stop offset="70%" stopColor={greenColor} stopOpacity={0.2} />
                  </linearGradient>
                </defs>
                <CartesianGrid fill="url(#background)" />
                <XAxis type="number" dataKey="worstHistoricalYield" tick={false}></XAxis>
                <YAxis type="number" dataKey="expectedYield" style={labelStyle}>
                  <Label style={labelStyle} dx={-4} angle={270} value={"Průměrný výnos [% p.a.]"} />
                </YAxis>
                <Tooltip content={<CustomTooltip />} wrapperStyle={wrapperStyle} />
                <Scatter name="scatterData" data={scatterData} shape={<ShapeDefault />} isAnimationActive={false} />
                {highlightedScatterData.map((item, index) => (
                  <Scatter
                    key={index}
                    name="highlightedScatterData"
                    data={[item.item]}
                    fill={item.color}
                    shape={<ShapeHighlighted />}
                    stroke="#000000"
                    strokeWidth={strokeWidth}
                    isAnimationActive={false}
                  />
                ))}
                {selectedMutualFund && (
                  <Scatter
                    name="selectedScatterData"
                    data={[selectedMutualFund]}
                    shape={<ShapeSelected />}
                    isAnimationActive={false}
                  />
                )}
              </ScatterChart>
            </ResponsiveContainer>
            <div className={styles.xAxisLabelsContainer}>
              <div className={styles.ticksContainer}>
                <div style={{ backgroundColor: redColor }}>Vysoké riziko ztráty</div>
                <div style={{ backgroundColor: "var(--fastdo-primary-color)" }}>Střední riziko ztráty</div>
                <div style={{ backgroundColor: greenColor }}>Nízké riziko ztráty</div>
              </div>
            </div>
            {selectedMutualFund && mutualFundsCount && (
              <div className={styles.descriptionDiv}>
                <p>Z grafu je vidět, že váš fond:</p>
                <ul>
                  <li>
                    <div>
                      <p>
                        <strong>
                          {selectedMutualFund.order! > 100
                            ? "nepatří mezi 100 nejkvalitnějších, proto bychom vám doporučili přesměrovat investici do našich široce diverzifikovaných portfolií KOPMPAS."
                            : "patří mezi 100 nejkvalitnějších, gratulujeme. I tak si vám dovolujeme nabídnout možnost přesměrovat vaši investici do našich široce diverzifikovaných portfolií KOPMPAS."}
                        </strong>
                      </p>
                      <ButtonWithInProgressModal />
                      <p>
                        <strong>pokud k nim potřebujete více informací:</strong>
                      </p>
                      <Button label="Chci vědět víc" onClick={onWantKnowMoreClick} />
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
