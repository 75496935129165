import { Spacer } from "@fastdo-react"
import { Area, AreaChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { labelStyle, wrapperStyle } from "./common"
import styles from "./FunnelChart.module.css"
import FunnelChartTooltip from "./FunnelChartTooltip"

export interface Props {
  data: any[]
}

export default function FunnelChart({ data }: Props) {
  const createXTicks = (start: number, end: number) => Array.from({ length: end - start + 1 }, (_, i) => i + start)

  return (
    <div>
      <h2>Trychtýřová metoda: porovnání historie</h2>
      <p>
        Vyobrazení výnosů všech historicky uskutečnitelných investic pro všechny investiční horizonty. Zobrazena je
        společná historie obou fondů. Díky tomu vidíme, jak si vedly ve stejných tržních podmínkách.
      </p>
      <Spacer size={20} />

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={500}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorA" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#e76f51" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#e76f51" stopOpacity={0.3} />
              </linearGradient>
              <linearGradient id="colorB" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#264653" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#264653" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <Legend iconType="plainline" verticalAlign="top" wrapperStyle={{ paddingBottom: "1rem" }} />
            <YAxis>
              <Label style={labelStyle} angle={270} dx={-20} value={"Roční výnos [%]"} />
            </YAxis>
            <XAxis
              dataKey="name"
              angle={0}
              dx={0}
              dy={20}
              height={55}
              tickFormatter={(value) => {
                if (Number.isInteger(value)) return value
                return ""
              }}
              type="number"
              ticks={createXTicks(1, Math.floor(data[data.length - 1].name))}
              domain={[1, data[data.length - 1].name]}
            />
            <Tooltip content={<FunnelChartTooltip />} wrapperStyle={wrapperStyle} />
            <Area
              type="monotone"
              dataKey="maxA"
              name="Maximum A"
              stroke="#e76f51"
              strokeWidth={2.5}
              dot={false}
              strokeDasharray="3 3"
              fill="url(#colorA)"
            />
            <Area
              type="monotone"
              dataKey="maxB"
              name="Maximum B"
              stroke="#264653"
              strokeWidth={2.5}
              dot={false}
              strokeDasharray="3 3"
              fill="url(#colorB)"
            />
            <Area
              type="monotone"
              dataKey="medA"
              name="Průměr A"
              stroke="#e76f51"
              strokeWidth={2.5}
              strokeDasharray="12 3"
              dot={false}
            />
            <Area
              type="monotone"
              dataKey="medB"
              name="Průměr B"
              stroke="#264653"
              strokeWidth={2.5}
              strokeDasharray="12 3"
              dot={false}
            />
            <Area type="monotone" dataKey="minA" name="Minimum A" stroke="#e76f51" strokeWidth={2.5} dot={false} />
            <Area type="monotone" dataKey="minB" name="Minimum B" stroke="#264653" strokeWidth={2.5} dot={false} />
          </AreaChart>
        </ResponsiveContainer>
        <p style={labelStyle}>Investiční horizont [let]</p>
      </div>
    </div>
  )
}
