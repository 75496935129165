import styles from "./Table.module.css"
import { useEffect, useRef, useState } from "react"
import TableItem from "./TableItem"
import {
  MutualFunds_Get_GetMutualFundsFilterRequest,
  MutualFunds_Get_GetMutualFundsItemResponse,
  MutualFunds_Get_GetRequest,
  MutualFunds_Get_GetResponse,
} from "common/api/exportedModels"
import agent from "common/api/agent"
import FilterCard, { defaultFilter } from "./FilterCard"
import { useMediaQuery } from "@mui/material"
import RecommendationTextField from "../RecommendationTextField"
import ScatterHighlightModal from "../ScatterHighlightModal"
import DeleteModal from "../DeleteModal"
import { PrimaryCircularProgress, PrimaryPagination, Spacer } from "@fastdo-react"
import { useAppSelector } from "common/storeHooks"
import InProgressModal from "components/general/InProgressModal"

export interface Props {
  isAdmin?: boolean
}

export default function Table({ isAdmin = false }: Props) {
  const totalCount = useAppSelector((state) => state.appData.response?.mutualFunds?.length)

  const [response, setResponse] = useState<MutualFunds_Get_GetResponse | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const [request, setRequest] = useState<MutualFunds_Get_GetRequest>({
    pageNumber: 1,
    pageSize: 10,
    filter: defaultFilter,
    searchId: undefined,
  })

  const [openedId, setOpenedId] = useState<string | undefined>(undefined)
  const [showFilter, setShowFilter] = useState(false)

  const get = async (customRequest: MutualFunds_Get_GetRequest | undefined = undefined) => {
    setLoading(true)
    if (customRequest) {
      if (customRequest?.searchId === request.searchId) customRequest.searchId = undefined
      setRequest(customRequest)
    }
    var response = await agent.MutualFunds.get(customRequest ? customRequest : request)
    if (response.searchedMutualFundPage)
      setRequest({ ...(customRequest ? customRequest : request), pageNumber: response.searchedMutualFundPage })
    setResponse(response)
    setLoading(false)
  }

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [initialFilterChange, setInitialFilterChange] = useState(true)

  const handleFilterChange = (filter: MutualFunds_Get_GetMutualFundsFilterRequest) => {
    if (initialFilterChange) {
      setInitialFilterChange(false)
      return
    }
    get({
      ...request,
      filter: filter,
      pageNumber: 1,
    })
  }

  const closeFilter = () => {
    setInitialFilterChange(true)
    setShowFilter(false)
  }

  const getPagesCount = () => {
    if (response && response.totalCount) {
      if (response.totalCount === 0) return 1
      return Math.ceil(response.totalCount / request.pageSize!)
    } else {
      if (totalCount === 0) return 1
      return Math.ceil((totalCount ?? 1) / request.pageSize!)
    }
  }

  const [highlightItem, setHighlightItem] = useState<MutualFunds_Get_GetMutualFundsItemResponse | undefined>(undefined)
  const [deleteItem, setDeleteItem] = useState<MutualFunds_Get_GetMutualFundsItemResponse | undefined>(undefined)

  const handleScatterHighlightModalClose = (performed: boolean) => {
    setHighlightItem(undefined)
    setOpenedId(undefined)
  }

  const mobileWidth = useMediaQuery("(max-width:600px)")
  const tableDivRef = useRef<any>(null)
  const executeScrollToTable = () => tableDivRef.current.scrollIntoView({ behavior: "smooth" })

  const [showInProgressModal, setShowInProgressModal] = useState(false)

  return (
    <div className={styles.component}>
      {showInProgressModal && <InProgressModal onClose={() => setShowInProgressModal(false)} />}

      {isAdmin && (
        <>
          {highlightItem && (
            <ScatterHighlightModal mutualFund={highlightItem} onClose={handleScatterHighlightModalClose} />
          )}
          {deleteItem && (
            <DeleteModal
              mutualFund={deleteItem}
              onClose={(performed) => {
                if (performed) get()
                setDeleteItem(undefined)
              }}
            />
          )}
        </>
      )}

      <div className={styles.commandsDiv}>
        <RecommendationTextField
          filter={request.filter}
          onRecommendationClick={async (id: string) => {
            await get({ ...request, searchId: id })
            executeScrollToTable()
          }}
        />
        <Spacer size={20} />

        <div className={styles.filterTextDiv}>
          <p className={styles.filterText} onClick={() => setShowFilter((_) => !_)}>
            Filtr
          </p>
        </div>
      </div>
      <Spacer />

      <div className={styles.filerCardDiv}>
        {showFilter && (
          <FilterCard
            filter={request.filter!}
            onFilterChange={(filter) => handleFilterChange(filter)}
            onClose={() => closeFilter()}
          />
        )}
      </div>
      <Spacer size={60} />

      <div className={styles.paginationDiv}>
        <PrimaryPagination
          page={request.pageNumber!}
          pagesCount={getPagesCount()}
          onChange={(page) => {
            get({ ...request, pageNumber: page })
          }}
          siblingCount={mobileWidth ? 1 : 3}
        />
      </div>

      <div className={styles.tableDiv} ref={tableDivRef}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <PrimaryCircularProgress size={120} />
          </div>
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Pořadí</th>
                <th>Kvalita</th>
                <th>
                  Název <br /> ISIN
                </th>
                <th>
                  Investiční <br /> společnost
                </th>
                <th>
                  Třída <br /> aktiv
                </th>
                <th>Měna</th>
                <th>
                  Stáři fondu <br /> [let]
                </th>
                <th>
                  Průměrný <br /> výnos
                </th>
                <th>
                  Nejnižší <br /> výnos
                </th>
              </tr>
            </thead>
            <tbody>
              {response?.items?.map((item) => (
                <TableItem
                  key={item.id}
                  item={item}
                  searched={item.id === request.searchId}
                  opened={openedId === item.id}
                  isAdmin={isAdmin}
                  onClick={setOpenedId}
                  onHighlightClick={setHighlightItem}
                  onDeleteClick={setDeleteItem}
                  onArrangeOnlineClick={() => setShowInProgressModal(true)}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
