import { HomeLayout } from "@fastdo-react"
import AdminArticleList from "components/pages/articles/AdminArticleList"

export default function ArticlesPage() {
  return (
    <HomeLayout title="Články">
      <AdminArticleList />
    </HomeLayout>
  )
}
