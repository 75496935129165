import styles from "./FunnelChartTooltip.module.css"
import { Spacer } from "@fastdo-react"

export default function FunnelChartTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    var aMax = payload[0].value[0]
    var bMax = payload[1].value[0]
    var aAvg = payload[2].value[0]
    var bAvg = payload[3].value[0]
    var aMin = payload[4].value[0]
    var bMin = payload[5].value[0]

    return (
      <div className={styles.component}>
        <p className={styles.labelP}>Rok: {label}</p>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>Maximum A: </p>
          <p className={styles.aLabelValueP}>{aMax}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>Maximum B: </p>
          <p className={styles.bLabelValueP}>{bMax}</p>
        </div>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>Průměr A: </p>
          <p className={styles.aLabelValueP}>{aAvg}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>Průměr B: </p>
          <p className={styles.bLabelValueP}>{bAvg}</p>
        </div>
        <Spacer />

        <div className={styles.labelDiv}>
          <p className={styles.aLabelP}>Minimum A: </p>
          <p className={styles.aLabelValueP}>{aMin}</p>
        </div>
        <div className={styles.labelDiv}>
          <p className={styles.bLabelP}>Minimum B: </p>
          <p className={styles.bLabelValueP}>{bMin}</p>
        </div>
      </div>
    )
  }

  return null
}
