import View from "./View"
import CreateArticlePage from "./back/CreateArticlePage"
import EditArticlePage from "./back/EditArticlePage"
import AnalysisPage from "./main/AnalysisPage"
import ArticlesPage from "./main/ArticlesPage"
import HomePage from "./main/HomePage"
import MediaPage from "./main/MediaPage"
import MutualFundsPage from "./main/MutualFundsPage"
import ScatterHighlightsPage from "./main/ScatterHighlightsPage"

export const routes = {
  path: "admin",
  element: <View />,
  children: [
    { path: "", element: <HomePage /> },
    { path: "mutualfunds", element: <MutualFundsPage /> },
    { path: "highlights", element: <ScatterHighlightsPage /> },
    { path: "analysis", element: <AnalysisPage /> },
    { path: "articles", element: <ArticlesPage /> },
    { path: "articles/create", element: <CreateArticlePage /> },
    { path: "articles/edit/:nameUrl", element: <EditArticlePage /> },
    { path: "media", element: <MediaPage /> },
  ],
}
