import styles from "./HomeTitle.module.css"

export default function HomeTitle() {
  return (
    <div className={styles.component}>
      <h1>
        On-line srovnávač podílových fondů
        <br />
        Dáváme investicím správný směr
      </h1>
    </div>
  )
}
