import { Card } from "@fastdo-react"
import styles from "./MemberCard.module.css"

export interface Props {
  imgSrc: string
  name: string
  text?: string
  picureRight?: boolean
  children?: JSX.Element | JSX.Element[]
}

export default function MemberCard({ imgSrc, name, text = "", picureRight = false, children = <></> }: Props) {
  const getComponentClass = () => {
    let className = styles.component
    if (picureRight) className += " " + styles.pictureRight
    return className
  }

  const getTextContainerClass = () => {
    if (picureRight) return styles.textLeft
    return styles.textRight
  }

  return (
    <Card>
      <div className={getComponentClass()}>
        <div className={styles.imgBox}>
          <img src={imgSrc} alt={name} title={name} loading="eager" />
        </div>
        <div className={styles.textBox}>
          <div className={getTextContainerClass()}>
            <h2>{name}</h2>
            {text ? <p>{text}</p> : children}
          </div>
        </div>
      </div>
    </Card>
  )
}
